.main-content {
    &.article {
        &.article-list {
            &.category-list {
                border-bottom: 0;
                max-width: 1300px;
                nav.category-nav ul {
                    @media (max-width: 600px) {
                        text-align: center;
                        li {
                            padding: 0 10px;
                            &:first-of-type {
                                padding-left: 10px;
                            }
                        }
                    }
                }
                &.story-list-element {
                    margin-top: 2rem;
                }
            }
        }
    }
}

.category-list {
    .main-content {
        width: 1300px;
        max-width: 100%;
        @media (max-width: 1400px) {
            width: auto;
        }
        .home-featured-stories {
            display: grid;
            grid-gap: 30px;
            grid-template-columns: 1fr;
            @media (max-width: 1000px) {
                grid-template-columns: 1fr;
            }
            .special-article-feature {
                margin-top: 15px;
                list-style-type: none;
                display: grid;
                grid-template-columns: 1fr 300px 300px;
                grid-gap: 30px;
                @media (max-width: 800px) {
                    display: block;
                }
                @media (max-width: 600px) {
                    margin-top: 10px;
                }
                .article-list-item {
                    @media (max-width: 600px) {
                        margin-bottom: 50px;
                    }
                    h3 {
                        margin: 0.75rem 0 0 0;
                    }
                    &:first-child {
                        grid-column: 1;
                        grid-row: 1 / last-line;
                        grid-row-start: span 3;
                        position: relative;
                        z-index: 3;
                        h3 {
                            font-family: $playfair;
                            font-size: 2.25rem;
                            font-weight: 300;
                            margin: 1rem 0 0 0;
                            line-height: 2.5rem;
                            @media (max-width: 600px) {
                                font-size: 2rem;
                                line-height: 1;
                            }
                        }
                        p.section {
                            margin: 1.5rem 0 1rem;
                        }
                        p.article-dek {
                            font-family: $opensans;
                            font-size: 1.2rem;
                            @media (max-width: 600px) {
                                font-size: 1.1rem;
                            }
                        }
                        p.author {
                            font-family: $opensans;
                            line-height: 1.7;
                            text-transform: uppercase;
                            font-weight: 700;
                            font-size: 0.95rem;
                        }
                    }
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
        .smart-news {
            .category-label {
                margin-bottom: 0;
                @media (max-width: 600px) {
                    margin-top: 0;
                }
            }
            .home-smart-news {
                .article-list {
                    border: 0;
                }
                h3 {
                    font-weight: 400;
                    line-height: 1.35rem;
                    margin: 10px 0 0 0;
                    padding: 0;
                    &:last-of-type {
                        margin: 4px 0 10px 0;
                    }
                }
                p {
                    margin-top: 4px;
                    font-family: $opensans;
                    font-size: 0.85rem;
                    font-weight: 600;
                    &.section {
                        &.larger {
                            margin-top: 24px;
                            margin-bottom: 0;
                            padding-bottom: 0;
                        }
                    }
                }
                hr {
                    border: 0;
                    &:before {
                        background: $black;
                        height: 2px;
                        width: 60%;
                        content: '';
                        display: inline-block;
                        vertical-align: top;
                        margin-top: 7px;
                        @media (max-width: 600px) {
                            width: 100%;
                        }
                    }
                }
            }
        }
        .homepage-featured-categories {
            .home-category-stories {
                margin-top: -10px;
                list-style-type: none;
                display: grid;
                grid-template-columns: 1fr 300px;
                grid-gap: 30px;
                @media (max-width: 800px) {
                    grid-template-columns: 1fr;
                }
                .article-list-item {
                    h3 {
                        font-family: $playfair;
                        font-size: 1.5rem;
                        font-weight: 300;
                        margin: 5px 0 0;
                    }
                    &:first-child {
                        grid-column: 1;
                        grid-row: 1 / last-line;
                        grid-row-start: span 3;
                        position: relative;
                        z-index: 3;
                        img {
                            margin-bottom: 8px;
                        }
                        h3 {
                            font-family: $playfair;
                            font-size: 2.5rem;
                            font-weight: 300;
                            margin: 5px 0;
                            line-height: 2.5rem;
                            @media (max-width: 600px) {
                                font-size: 1.8rem;
                                line-height: 1;
                            }
                        }
                        p {
                            margin: 15px 0 0;
                            font-family: $opensans;
                        }
                    }
                }
                img {
                    width: 100%;
                    height: auto;
                }
                &.home-category-videos {
                    .article-list-image {
                        &.first-image {
                            position: relative;
                            a {
                                &:hover {
                                    h3 {
                                        text-decoration: underline;
                                    }
                                }
                                .play-container {
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                    background: rgb(255, 255, 255);
                                    background: linear-gradient(
                                        180deg,
                                        rgba(255, 255, 255, 0) 0%,
                                        rgba(0, 0, 0, 1) 75%
                                    );
                                    .play-content {
                                        padding: 2rem;
                                        @media (max-width: 800px) {
                                            padding: 1.5rem;
                                        }
                                        @media (max-width: 400px) {
                                            padding: 0.5rem;
                                        }
                                        h3 {
                                            color: $white;
                                            margin-bottom: 2rem;
                                            @media (max-width: 800px) {
                                                margin-bottom: 5vw;
                                                font-size: 8vw;
                                                line-height: 1;
                                            }
                                            a {
                                                color: $white;
                                            }
                                        }
                                        i {
                                            font-size: 4rem;
                                            color: $white;
                                            display: inline;
                                            vertical-align: middle;
                                            @media (max-width: 600px) {
                                                font-size: 10vw;
                                            }
                                        }
                                        .play-btn {
                                            margin: 0;
                                        }
                                        .play-txt {
                                            text-transform: uppercase;
                                            color: #fff;
                                            font-family: 'Open Sans';
                                            font-weight: 700;
                                            letter-spacing: 1px;
                                            display: inline;
                                            margin-left: 10px;
                                            font-size: 1.1rem;
                                        }
                                    }
                                }
                            }
                        }
                        .play-container .play-btn {
                            margin: -30px 0 20px 10px;
                            color: #fff;
                            text-shadow: 0 0 10px #999;
                        }
                    }
                }
            }
        }
        &.lower-content {
            display: grid;
            grid-gap: 30px;
            grid-template-columns: 1fr 300px;
            margin-top: 4rem;
            @media (max-width: 1300px) {
                grid-template-columns: 1fr;
            }
            .current-issue {
                background: $black;
                padding: 50px;
                margin-bottom: 3rem;
                @media (max-width: 600px) {
                    margin-left: -20px;
                    margin-right: -20px;
                    padding: 50px 0;
                }
                .issue-container {
                    &:before {
                        border-top: 3px solid $white;
                        border-left: 3px solid $white;
                        height: 150px;
                        width: 325px;
                        content: '';
                        display: inline-block;
                        @media (max-width: 1000px) {
                            border-top: none;
                            border-left: none;
                        }
                    }
                    .contained-issue {
                        position: relative;
                        margin: 0 auto;
                        margin-top: -180px;
                        .issue-positioning {
                            display: grid;
                            grid-gap: 50px;
                            grid-template-columns: 232px 1fr;
                            margin-top: 3rem;
                            align-items: center;
                            margin-left: 50px;
                            @media (max-width: 700px) {
                                margin: 1.8rem auto 0;
                                text-align: center;
                                grid-template-columns: 1fr;
                            }
                            .issue-left {
                                position: relative;
                                &:after {
                                    border-bottom: 3px solid $white;
                                    height: 100px;
                                    width: 200px;
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    right: -3px;
                                    bottom: 1px;
                                    border-right: 3px solid $white;
                                    @media (max-width: 700px) {
                                        display: none;
                                    }
                                }
                            }
                            .issue-right {
                                @media (max-width: 600px) {
                                    padding: 0 40px;
                                }
                                .story-list {
                                    margin: 0;
                                    padding: 0;
                                    list-style-type: none;
                                    li {
                                        font-size: 1.6rem;
                                        padding: 10px 0;
                                        @media (max-width: 600px) {
                                            font-size: 1.3rem;
                                            text-align: left;
                                        }
                                        &:first-of-type {
                                            padding-top: 0;
                                        }
                                        &:last-of-type {
                                            padding-top: 0;
                                            &:after {
                                                border-bottom: 0;
                                            }
                                        }
                                        &:after {
                                            border-bottom: 1px solid #808080;
                                            content: '';
                                            display: block;
                                            width: 200px;
                                            height: 1px;
                                            padding-top: 20px;
                                            @media (max-width: 700px) {
                                                width: 100%;
                                                margin: auto;
                                            }
                                        }
                                    }
                                }
                                .subscribe-btn {
                                    @media (max-width: 600px) {
                                        width: 100%;
                                    }
                                }
                                .gift-btn {
                                    @media (max-width: 600px) {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                    h2 {
                        color: $white;
                        text-align: center;
                        margin: 0;
                        padding: 0;
                        font-size: 2.5rem;
                        line-height: 2rem;
                        font-weight: 400;
                        @media (max-width: 600px) {
                            font-size: 1.8rem;
                        }
                        span {
                            font-family: $opensans;
                            font-size: 1.25rem;
                            letter-spacing: 2px;
                            text-transform: uppercase;
                            font-weight: 700;
                            @media (max-width: 600px) {
                                font-size: 0.8rem;
                            }
                        }
                    }
                    ol,
                    ul {
                        a {
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    p.section a:hover {
        text-decoration: none;
    }
}

.hide-toolbar {
    padding: 0;

    #cms-top {
        display: none;
    }

    header.main {
        margin-top: -46px;
    }

    header.main .navigation {
        top: 0 !important;
    }
}

.photo-of-the-day-main {
    img {
        max-width: 100%;
        margin-top: 30px;
    }
    .caption {
        font-size: 2rem;
        font-family: $playfair;
    }
}

.paginationSection {
    display: grid;
    grid-template-columns: 150px 1fr;
    gap: 10px;
    margin: 0 !important;
    .columns {
        &:last-of-type {
            justify-self: end;
        }
    }
    @media (max-width: 600px) {
        text-align: center;
        grid-template-columns: 1fr;
    }
    ul {
        list-style-type: none;
        @media (max-width: 600px) {
            margin: 0;
            padding: 0;
        }
        li {
            display: inline;
            border-right: 1px solid $black;
            &:last-of-type {
                border-right: 0;
            }
            a {
                color: $black;
                margin: 0 4px 0 7px;
                padding: 0;
                cursor: pointer;
                text-decoration: none;
                border-bottom: 0 !important;
                &.active {
                    font-weight: 700;
                }
                @media (max-width: 600px) {
                    margin: 0 4px;
                }
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
