/* black box dropped style anchor */
.binding-box {
    border-top: 4px solid $black;
    border-bottom: 4px solid $black;
    &.nobottom {
        border-bottom: 0;
    }
    &:before {
        background: $black;
        height: 35px;
        width: 4px;
        content: '';
        display: inline-block;
        vertical-align: top;
    }
    h2 {
        text-transform: uppercase;
        font-family: $opensans;
        letter-spacing: 0.15rem;
        margin-bottom: 10px;
        font-size: 1.15rem;
        span {
            border-bottom: 2px solid $black;
        }
    }
    .list-no-images {
        padding: 0 20px 20px;
        margin-top: -36px;
        .list-elements {
            padding: 20px 0 5px;
            h3 {
                font-weight: 400;
                line-height: 1.35rem;
                margin: 4px 0 22px 0;
                padding: 0;
                &:last-of-type {
                    margin: 4px 0 10px 0;
                }
            }
            .section {
                line-height: 0.85rem;
                margin: 0;
                padding: 0;
            }
            hr {
                border: 0;
                &:before {
                    background: $black;
                    height: 2px;
                    width: 60%;
                    content: '';
                    display: inline-block;
                    vertical-align: top;
                }
            }
        }
    }
}

.list-no-images {
    padding: 0 20px 0 0;
    &.first {
        &.article-sidebar {
            padding: 0 20px 0 0;
            .article-list-text {
                padding: 20px 0 5px 20px;
            }
            hr {
                margin-left: 20px;
            }
        }
    }
    &.article-sidebar {
        padding: 0 20px 0 0;
        .article-list-text {
            padding: 0 0 5px 20px;
        }
        hr {
            margin-left: 20px;
        }
    }
    .list-elements,
    .article-list-text {
        padding: 20px 0 5px 20px;
        h3 {
            font-weight: 400;
            line-height: 1.35rem;
            margin: 4px 0 22px 0;
            padding: 0;
            font-size: 1.17rem;
            &:last-of-type {
                margin: 4px 0 10px 0;
            }
        }
        .section {
            line-height: 0.85rem;
            margin: 0;
            padding: 0;
            &.larger {
                padding-left: 20px;
            }
        }
        hr {
            border: 0;
            &:before {
                background: $black;
                height: 2px;
                width: 60%;
                content: '';
                display: inline-block;
                vertical-align: top;
            }
        }
    }
    hr {
        border: 0;
        &:before {
            background: $black;
            height: 2px;
            width: 60%;
            content: '';
            display: inline-block;
            vertical-align: top;
        }
    }
    .section {
        &.larger {
            padding-left: 20px;
        }
    }
}

/* embedded slideshows */
.slideshow-wrapper {
    margin: 50px auto;
    padding: 50px 20px;
    border-bottom: 1px solid #ececec;
    border-top: 1px solid #ececec;
    .slideshow {
        position: relative;
        .slideshow-prev:hover,
        .slideshow-next:hover {
            text-decoration: none;
        }
        .fa-chevron-left,
        .fa-chevron-right,
        .fa-chevron-square-left,
        .fa-chevron-square-right {
            color: #333;
            font-size: 3rem;
            cursor: pointer;
            &:hover {
                color: $black;
                text-decoration: none;
            }
        }
        .fa-chevron-right,
        .fa-chevron-square-right {
            margin-left: 10px;
            left: inherit;
            right: 80px;
        }
        .slideshow-slides {
            .slide {
                display: none;
                figure {
                    width: 100%;
                    margin: 0 auto;
                    img {
                        width: 100%;
                    }
                    p.credit {
                        margin: 5px 0;
                        color: #ccc;
                        font-family: sans-serif;
                        font-size: 0.7rem;
                        text-transform: uppercase;
                    }
                    .caption {
                        color: #666;
                        font-family: sans-serif;
                        font-size: 0.9rem;
                    }
                }
                &:first-of-type {
                    display: block;
                }
            }
        }
        .slide-info {
            width: 100%;
            .slide-number {
                margin: 20px 0 30px;
                display: inline-block;
                .current-slide {
                    font-weight: bold;
                }
            }
            .slide-controls {
                margin-top: 20px;
                float: right;
            }
        }
    }
}

/* most popular box */
.most-popular-large {
    margin: 40px 0;
    .article-list-item {
        display: grid;
        grid-template-columns: 20px 250px 1fr;
        grid-gap: 30px;
        border-bottom: 1px solid #ccc;
        padding: 30px 0;
        align-items: center;
        grid-row-gap: 40px;
        &:last-child {
            border: 0;
        }
        &:first-child {
            padding-top: 0;
        }
        @media (max-width: 600px) {
            text-align: left;
        }
        @media (max-width: 600px) {
            text-align: center;
            display: block !important;
        }
        .article-list-counter {
            font-family: $playfair;
            font-size: 2.5rem;
            @media (max-width: 600px) {
                line-height: 1;
                display: inline-block;
            }
        }
        .article-list-image {
            img {
                width: 250px;
                height: auto;
                @media (max-width: 600px) {
                    width: 100%;
                }
            }
        }
        .article-list-text {
            display: inline-block;
            vertical-align: middle;
            @media (max-width: 600px) {
                width: 80%;
                margin: 10px 20px 50px;
                text-align: left;
                vertical-align: top;
            }
            @media (max-width: 400px) {
                margin: 10px 10px 50px;
            }
            h3 {
                font-family: $playfair;
                font-weight: normal;
                padding: 13px 0;
                margin: 0;
                font-size: 1.7rem;
                line-height: 1.1;
                @media (max-width: 600px) {
                    padding: 10px 0 0;
                    font-size: 1.3rem;
                }
            }
            .article-series {
                padding: 0;
                margin: 0 0 4px 0;
                text-transform: uppercase;
                font-weight: 600;
                font-family: $opensans;
                font-size: 0.95rem;
                letter-spacing: 1px;
            }
            .article-dek {
                font-family: $opensans;
                padding: 0;
                margin: 7px 0 0 0;
                font-size: 0.95rem;
            }
            .article-date {
                display: block;
            }
            .article-author {
                font-family: $opensans;
                font-weight: 700;
                font-size: 0.9rem;
                margin: 9px 0 0 0;
                padding: 0;
                a {
                    border-bottom: 0;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            .article-published {
                font-family: $opensans;
                font-size: 0.9rem;
                margin: 9px 0 0 0;
                padding: 0;
                font-weight: 600;
            }
        }
    }
}

/* Sidebar */
aside {
    &.scrolled {
        position: fixed;
        top: 80px;
    }
    .heading {
        height: auto;
        margin-bottom: 50px;
    }
}

/* reusable sidebar story list */
.sidebar-story-list {
    padding: 0 20px 20px;
    margin-top: -43px;
    ol {
        margin: 0 0 0 15px;
        padding: 0;
        line-height: inherit;
        li {
            padding-bottom: 10px;
            &:last-of-type {
                padding-bottom: 0;
            }
            &::marker {
                font-family: $playfair;
                font-weight: 300;
                font-size: 2.5rem;
                vertical-align: top;
                position: relative;
                line-height: 1;
            }
            .containment {
                display: inline-grid;
                grid-template-columns: 76px 1fr;
                grid-gap: 15px;
                vertical-align: top;
                .thumb {
                    width: 76px;
                    height: 76px;
                }
                .headline {
                    h5 {
                        font-weight: 400;
                        margin: 0;
                        padding: 0;
                        line-height: 1.1;
                        font-family: $opensans;
                        a {
                            border: 0;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}

.section {
    font-family: $opensans;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 0.85rem;
    text-decoration: none;
    &.upper {
        text-transform: uppercase;
    }
    &.larger {
        font-size: 1rem;
        text-transform: uppercase;
        a {
            text-decoration: none;
            font-size: 1rem;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    a {
        font-family: $opensans;
        font-weight: 700;
        letter-spacing: 1px;
        font-size: 0.85rem;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

.article-list-item {
    &.sponsored {
        h3 {
            margin-bottom: 10px;
        }
    }
    h3 {
        font-weight: 400;
        font-size: 1.5rem;
        margin-top: 0;
        line-height: 1.75rem;
        margin-bottom: 8px;
    }
    p.section {
        margin-bottom: 11px;
    }
    p.article-published {
        margin-top: 4px;
        font-family: $opensans;
        font-weight: 600;
        font-size: 0.9rem;
    }
}

// color overrides
.arts-culture {
    &.section {
        a {
            border-bottom: 3px solid $artsculture;
            &:hover {
                text-decoration: none;
            }
        }
    }
    blockquote {
        border-left: 6px solid $artsculture;
    }
    .category-label {
        h2,
        span {
            @media (max-width: 350px) {
                background-color: $artsculture;
            }
            &:after {
                background-color: $artsculture;
            }
        }
    }
}
.history {
    &.section {
        a {
            border-bottom: 3px solid $history;
            &:hover {
                text-decoration: none;
            }
        }
    }
    blockquote {
        border-left: 6px solid $history;
    }
    .category-label {
        h2,
        span {
            @media (max-width: 350px) {
                background-color: $history;
            }
            &:after {
                background-color: $history;
            }
        }
    }
}
.innovation {
    &.section {
        a {
            border-bottom: 3px solid $innovation;
            &:hover {
                text-decoration: none;
            }
        }
    }
    blockquote {
        border-left: 6px solid $innovation;
    }
    .category-label {
        h2,
        span {
            @media (max-width: 350px) {
                background-color: $innovation;
            }
            &:after {
                background-color: $innovation;
            }
        }
    }
}
.science,
.science-nature {
    &.section {
        a {
            border-bottom: 3px solid $science;
            &:hover {
                text-decoration: none;
            }
        }
    }
    blockquote {
        border-left: 6px solid $science;
    }
    .category-label {
        h2,
        span {
            @media (max-width: 350px) {
                background-color: $science;
            }
            &:after {
                background-color: $science;
            }
        }
    }
}
.smart-news {
    &.section {
        a {
            border-bottom: 3px solid $smartnews;
            &:hover {
                text-decoration: none;
            }
        }
    }
    blockquote {
        border-left: 6px solid $smartnews;
    }
    .category-label {
        h2,
        span {
            @media (max-width: 350px) {
                background-color: $smartnews;
            }
            &:after {
                background-color: $smartnews;
            }
        }
    }
}
.smithsonian-institution,
.at-the-smithsonian {
    &.section {
        a {
            border-bottom: 3px solid $smithsonian;
            &:hover {
                text-decoration: none;
            }
        }
    }
    blockquote {
        border-left: 6px solid $smithsonian;
    }
    .category-label {
        h2,
        span {
            @media (max-width: 350px) {
                background-color: $smithsonian;
            }
            &:after {
                background-color: $smithsonian;
            }
        }
    }
}
.travel {
    &.section {
        a {
            border-bottom: 3px solid $travel;
            &:hover {
                text-decoration: none;
            }
        }
    }
    blockquote {
        border-left: 6px solid $travel;
    }
    .category-label {
        h2,
        span {
            @media (max-width: 350px) {
                background-color: $travel;
            }
            &:after {
                background-color: $travel;
            }
        }
    }
}

.category-label {
    margin-top: 45px;
    margin-bottom: 24px;
    font-family: $opensans;
    font-size: 1.1rem;
    line-height: 1.7;
    hr {
        width: 100% !important;
        height: 1rem !important;
        margin: 0 !important;
        background-color: $black;
        position: relative !important;
        z-index: 1 !important;
        border: 0 !important;
        @media (max-width: 400px) {
            //height: 15px !important;
        }
        @media (max-width: 350px) {
            display: none;
        }
    }
    h2,
    span {
        top: -0.9rem;
        padding: 0.1rem 1.5rem 0.2rem 0.9rem;
        background-color: $black;
        color: $white;
        font-weight: bold;
        font-family: $opensans;
        font-size: 1.2rem;
        line-height: 1.1;
        letter-spacing: 0.1rem;
        display: inline-block;
        position: relative;
        z-index: 1;
        text-transform: uppercase;
        margin: 0;
        a {
            color: $white;
            &:hover {
                text-decoration: none;
            }
        }
        &:after {
            content: '';
            width: 1.9rem;
            height: 0.8rem;
            background-color: #fff;
            display: block;
            position: absolute;
            top: 0.8rem;
            right: -1rem;
            transform: skewX(-45deg);
            @media (max-width: 400px) {
                // height: 14px;
                // top: 10px;
            }
            @media (max-width: 350px) {
                display: none;
            }
        }
    }
}

// Category label Firefox fix
@-moz-document url-prefix() {
    .category-label h2:after {
        top: 11px;
        height: 14.2px;
    }
}

.sponsored-article {
    padding: 15px;
    border: 3px solid $black;
    position: relative;
    z-index: 2;

    &:before,
    &:after {
        content: '';
        width: 100px;
        height: 100px;
        background-color: $white;
        transform: rotate(45deg);
        position: absolute;
        top: -20px;
        right: -20px;
        display: block;
        z-index: 1;
    }

    &:after {
        top: inherit;
        left: inherit;
        left: -20px;
        bottom: -20px;
    }

    img {
        width: 100%;
        position: relative;
        z-index: 2;
    }

    .label {
        position: relative;
        z-index: 2;
    }

    h3 {
        position: relative;
        z-index: 2;
    }
}

/* plugin: special report */
.home-special-report-container {
    .special-report-plugin {
        .special-sponsored {
            padding: 20px;
            background: #f6f6f6;
            text-align: center;
            text-transform: uppercase;
            font-family: $opensans;
            letter-spacing: 1px;
            font-size: 0.75rem;
            font-weight: 600;
            border-bottom: 3px solid $black;
            img {
                width: auto;
                height: 50px;
                margin-left: 20px;
                vertical-align: middle;
                @media (max-width: 600px) {
                    margin: 20px auto 0;
                    display: block;
                }
            }
        }
        hr {
            height: 3px;
            background: $black;
            border: 0;
        }
        .special-text-heading {
            text-align: center;
            .special-report-heading {
                background: $black;
                padding: 5px 14px;
                color: #fff;
                text-transform: uppercase;
                font-family: $opensans;
                font-weight: 700;
                position: relative;
                top: -20px;
            }
        }
        .special-plugin-container {
            padding: 30px 0 50px;
            border-bottom: 3px solid #000;
            display: grid;
            grid-template-columns: 270px 1fr 1fr;
            grid-gap: 35px;
            @media (max-width: 800px) {
                grid-template-columns: 1fr 1fr;
            }
            @media (max-width: 600px) {
                grid-template-columns: 1fr;
            }
            .special-item {
                img {
                    width: 100%;
                    height: auto;
                }
                p {
                    margin-top: 30px;
                    font-family: $opensans;
                    font-size: 0.85rem;
                    img {
                        width: 90px;
                        margin-left: 12px;
                    }
                    &.section.larger {
                        text-align: right;
                    }
                }
                hr {
                    height: auto;
                    background: transparent;
                    border: 0;
                    &:before {
                        border-top: 2px solid $black;
                        width: 40%;
                        content: '';
                        display: inline-block;
                        vertical-align: top;
                    }
                }
            }
        }
        h2 {
            margin: 0 0 20px;
            padding: 0;
            font-family: $playfair;
            font-size: 1.75rem;
            font-weight: normal;
            line-height: 1;
        }
        h3 {
            margin: 0 0 15px;
            padding: 0;
            font-size: 1.05rem;
            font-weight: 400;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        h4 {
            margin: 0 0 15px;
            font-family: $opensans;
            font-size: 0.85rem;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
        .second-item {
            padding-right: 50px;
            font-family: $opensans;
            font-size: 1rem;
            line-height: 1.5;
            @media (max-width: 1000px) {
                padding-right: 0;
            }
        }
        .third-item {
            padding-right: 50px;
        }
    }
}

.call-to-action {
    &.full-width {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        background: $black;
        margin: 50px 0 0;
        @media (max-width: 800px) {
            display: block;
        }
        .call-info {
            &:first-of-type {
                grid-column: 2;
                grid-row: 1 / last-line;
                img {
                    width: 100%;
                    height: auto;
                    display: block;
                }
            }
            &:last-of-type {
                color: $white;
                text-align: center;
                @media (max-width: 800px) {
                    padding: 10px 20px 20px;
                }
                h3 {
                    color: $white;
                    font-weight: 400;
                    font-size: 2.5rem;
                    margin: 0;
                    @media (max-width: 600px) {
                        font-size: 1.5rem;
                        line-height: 1;
                    }
                    a {
                        color: $white;
                    }
                }
                p {
                    color: $white;
                    font-family: $opensans;
                    font-size: 1.25rem;
                    margin: 20px 0;
                    @media (max-width: 900px) {
                        margin: 10px 0;
                    }
                    @media (max-width: 600px) {
                        font-size: 1rem;
                    }
                }
                button {
                    border: 2px solid $white;
                    background: $black;
                    text-transform: uppercase;
                    font-family: $opensans;
                    letter-spacing: 2px;
                    color: $white;
                    font-weight: 700;
                    font-size: 1.05rem;
                    @media (max-width: 600px) {
                        width: 200px;
                        font-size: 0.8rem;
                    }
                    &:hover {
                        background: $white;
                        a {
                            color: $black;
                        }
                    }
                    a {
                        color: $white;
                        text-decoration: none;
                        display: block;
                        padding: 5px 55px;
                        @media (max-width: 600px) {
                            padding: 5px 15px;
                        }
                    }
                }
            }
        }
    }
}

// Flatpage
.flatpage {
    .main-content {
        max-width: 1100px;
        margin: 50px auto 100px;
        @media (max-width: 1100px) {
            width: auto;
        }
        h1 {
            margin: 0 0 30px;
            font-size: 3rem;
            font-weight: normal;
        }
        h2 {
            margin: 0 0 20px;
            font-size: 2.3rem;
            font-weight: normal;
        }
        h3 {
            margin: 0 0 25px;
            font-size: 1.7rem;
            font-weight: normal;
        }
        p {
            margin: 0 0 25px;
            font-family: $opensans;
            font-size: 1.1rem;
            line-height: 1.85;
            a {
                text-decoration: underline;
            }
        }
        ul,
        ol {
            font-family: $opensans;
            font-size: 1.1rem;
            line-height: 1.85;
        }
        .two-column-grid {
            margin-bottom: 50px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 50px;

            @media (max-width: 1000px) {
                grid-template-columns: 1fr;
                gap: 20px;
            }
        }
        .three-column-grid {
            margin-bottom: 50px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 50px;

            @media (max-width: 1000px) {
                gap: 20px;
            }

            @media (max-width: 800px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width: 600px) {
                grid-template-columns: repeat(1, 1fr);
            }

            .three-column-grid-item {
                img {
                    width: 100%;
                }

                .title {
                    font-size: 1.5rem;
                    font-weight: 700;
                }
            }
        }
        .links-box {
            padding: 30px 40px;
            border: 3px solid #000;
            font-family: $opensans;
            font-size: 1.1rem;
            word-break: break-word;

            @media (max-width: 400px) {
                padding: 15px 20px;
            }
        }
        .black-box {
            height: 60px;
            padding: 20px 50px;
            background: #000;
            color: #fff;
            font-family: $opensans;
            font-size: 1.2rem;
            font-weight: bold;
            text-align: center;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .sidebar-page {
        margin: 60px auto;
        display: grid;
        grid-template-columns: 7fr 3fr;
        gap: 80px;

        @media (max-width: 1000px) {
            gap: 20px;
        }

        @media (max-width: 800px) {
            grid-template-columns: 1fr;
        }

        .sidebar {
            padding: 30px 40px;
            background-color: #f6f6f6;
            align-self: start;
            a {
                margin: 0 0 25px;
                font-family: $opensans;
                font-size: 1.1rem;
                display: block;
            }
        }
    }
    form {
        width: 530px;
        max-width: 100%;
        font-family: $opensans;

        @media (max-width: 600px) {
            width: auto;
        }

        label {
            margin-bottom: 8px;
            font-size: 0.8rem;
            display: block;

            .required {
                color: red;
            }
        }
        fieldset {
            margin: 0 0 20px;
            padding: 0;
            border: none;
            outline: none;
            position: relative;

            input,
            select,
            textarea {
                width: 100%;
                padding: 8px 12px;
                font-size: 0.9rem;
                box-sizing: border-box;
                display: block;
            }
            input[type='checkbox'] {
                width: auto;
                display: inline-block;
            }
            select {
                width: 100%;
                max-width: 100%;
                color: #9e9e9e;
                option:not(:first-of-type) {
                    color: #000;
                }
            }
            textarea {
                height: 180px;
            }
        }
        button,
        .button {
            width: 130px;
            margin: 40px 0;
            padding: 8px 20px;
            background-color: #000;
            color: #fff;
            font-size: 0.9rem;
            font-weight: bold;
            text-align: center;
            letter-spacing: 1px;
            display: block;
            cursor: pointer;
        }
    }
}

.heading {
    height: 0;
    margin: 0 0 10px;
    padding: 15px;
    border-top: 3px solid $black;
    border-left: 3px solid $black;
    font-family: $opensans;
    font-size: 1.2rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 2px;
    font-weight: 700;
}

// Member Services
.member-services {
    &.flatpage {
        .sidebar-page {
            margin: 60px auto;
            display: grid;
            grid-template-columns: 1fr 200px;
            gap: 30px;
            @media (max-width: 700px) {
                display: block;
            }
            .sidebar {
                @media (max-width: 700px) {
                    display: none;
                }
                h3 {
                    font-size: 1.2rem;
                }
            }
            figure {
                margin: 0;
                padding: 0;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
    .main-content {
        margin-top: 20px;
        h1 {
            margin-top: 0;
            margin-bottom: 20px;
            padding-top: 0;
            font-size: 2.5rem;
        }
        .heading {
            margin-bottom: 30px;
            font-weight: 700;
        }
        @media (max-width: 600px) {
            figure {
                margin: 0;
            }
            img {
                width: 100%;
                height: auto;
            }
            .sidebar {
                display: none;
            }
        }
    }
}

.sponsored-article {
    &.call-to-action {
        .article-list-item {
            text-align: left;
            &:first-child {
                text-align: left;
                h3 {
                    font-weight: 400;
                    font-size: 1.5rem;
                    margin-top: 0;
                    line-height: 1.75rem;
                    margin-bottom: 8px;
                }
            }
            h3 {
                font-weight: 400;
                font-size: 1.5rem;
                margin-top: 0;
                line-height: 1.75rem;
                margin-bottom: 8px;
            }
        }
    }
}

.ad-spacer {
    margin-top: 2rem;
}

/* Search form */
.search-form,
.flatpage .search-form {
    @media (max-width: 600px) {
        display: grid;
        grid-template-columns: 5fr 1fr;
        gap: 8px;
    }
    input {
        width: 380px;
        height: 38px;
        margin-right: 12px;
        padding: 10px 15px;
        font-size: 0.9rem;
        outline: none;
        box-sizing: border-box;
        display: inline-block;
        @media (max-width: 600px) {
            width: 100%;
        }
    }
    button {
        width: auto;
        margin: auto;
        padding: 10px 12px;
        border: none;
        color: #fff;
        font-size: 1rem;
        cursor: pointer;
        display: inline-block;
    }
}

.expedia {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 30px 0;
    margin: 30px 0;
    h3 {
        font-family: $opensans !important;
        margin-bottom: 0 !important;
    }
    p {
        margin-top: 0 !important;
        &.disclaimer {
            font-size: 0.9rem !important;
            margin-top: 15px !important;
            font-style: italic;
            line-height: 1.4 !important;
            margin-bottom: 0 !important;
        }
    }
    .eg-widget,
    .eg-widget-frame {
        max-width: 100% !important;
    }
}

.wp-caption {
    width: 100% !important;
    img {
        width: 100%;
        height: auto;
    }
}
