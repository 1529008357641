#voices {
    max-width: $desktop_default;
    margin: 0 auto;
    .sectionheading {
        border-bottom: 4px solid $black;
        margin-bottom: 33px;
        h2 {
            font-family: $playfair;
            font-size: 3rem;
            font-weight: 200;
            margin: 0;
            padding-bottom: 10px;
            text-align: left;
        }
    }
    .leadstories {
        border-bottom: 1px solid $grey;
        margin-bottom: 45px;
        .row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
        }
        .double-column {
            display: flex;
            flex-direction: column;
            flex-basis: 100%;
            flex: 2;
            @media screen and (min-width: 800px) {
                flex: 1;
            }
        }
        .column {
            display: flex;
            flex-direction: column;
            flex-basis: 100%;
            flex: 1;
            vertical-align: top;
            &.large {
                padding-right: 40px;
                h3 {
                    line-height: 33px;
                    margin: 0;
                    font-size: 1.8rem;
                }
                p {
                    margin: 12px 0;
                    padding: 0;
                }
                img {
                    padding: 0;
                }
                .voices-date {
                    padding-left: 20px;
                    display: inline-block;
                }
            }
            @media screen and (min-width: 800px) {
                flex: 1;
            }
            h3 {
                font-family: $opensans;
                font-size: 1.4rem;
                font-weight: 300;
                margin: 0 0 10px 0;
                line-height: 28px;
            }
            p {
                margin: 0 0 12px 0;
            }
            img {
                width: 100%;
                height: auto;
            }
            &.img {
                padding-right: 20px;
                padding-bottom: 55px;
            }
            &.text {
                padding-bottom: 55px;
            }
        }
    }
    .contentlist {
        margin-bottom: 45px;
        .row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            border-bottom: 1px solid $grey;
            margin-top: 40px;
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                border-bottom: 0;
            }
        }
        .double-column {
            width: 750px;
            margin-right: 50px;
            .largest {
                border-bottom: 1px solid $grey;
                .row {
                    padding-bottom: 20px;
                    .column {
                        &.img {
                            width: 100%;
                            margin-right: 0;
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
                        &.text {
                            width: 100%;
                            padding-top: 30px;
                        }
                    }
                }
            }
            .row {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;
                padding-bottom: 40px;
                .column {
                    display: flex;
                    flex-direction: column;
                    vertical-align: top;
                    &.img {
                        width: 250px;
                        margin-right: 45px;
                        img {
                            width: 250px;
                            height: auto;
                        }
                    }
                    &.text {
                        width: 455px;
                        h3 {
                            font-family: $opensans;
                            font-size: 1.4rem;
                            font-weight: 300;
                            margin: 0 0 10px 0;
                            line-height: 28px;
                        }
                        p {
                            margin: 0 0 12px 0;
                        }
                        .voices-date {
                            padding-left: 20px;
                            display: inline-block;
                        }
                    }
                }
            }
        }
        .column {
            width: 300px;
            vertical-align: top;
        }
    }
    .voices-byline {
        font-family: $opensans;
        font-size: 1rem;
        font-weight: 600;
    }
    .voices-date {
        font-family: $opensans;
        font-size: 1rem;
        font-weight: 400;
        display: block;
    }
}
