footer {
    width: 100%;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 35px;
    .footerLogo {
        border-top: 1px solid #8a8a8a;
        border-bottom: 1px solid #8a8a8a;
        margin: 0 auto;
        text-align: center;
        padding: 20px 0 15px;
        img {
            width: 120px;
            height: auto;
        }
    }
    .footerNav {
        max-width: $desktop_default;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 0 50px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 70px;
        width: 100%;

        @media (max-width: 1000px) {
            max-width: 90%;
        }

        @media (max-width: 815px) {
            padding: 0;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width: 600px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 20px 80px;
        }

        @media (max-width: 350px) {
            text-align: center;
            display: block;
        }

        .footerNavItem {
            p.spacing {
                margin-top: 30px;
            }
            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;
                li {
                    font-family: $opensans;
                    font-size: 0.9rem;
                    font-weight: 400;
                    line-height: 24px;
                    a {
                        font-family: $opensans;
                        font-size: 0.9rem;
                        font-weight: 400;
                        line-height: 24px;
                        border-bottom: 0;
                    }
                }
            }
        }
    }
    .footerLinks {
        margin-top: 30px;
        padding: 25px 15px 100px;
        border-top: 1px solid #8a8a8a;
        font-family: $opensans;
        font-size: 0.75rem;
        font-weight: 400;
        text-align: center;
        line-height: 24px;
        p {
            font-family: $opensans;
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 24px;
        }
        span {
            padding: 0 10px;
            @media (max-width: 815px) {
                display: block;
            }
            @media (max-width: 600px) {
                text-align: left;
            }
        }
    }
}
