body.longform {
    display: grid;
    place-items: center;

    .article-special-wrap {
        border-top: 1px solid #ccc;
        width: 100%;
        .article-special {
            width: 100%;
            @media (max-width: 600px) {
                width: auto;
            }
            .article-special-text {
                padding: 0 15px;
            }
        }
    }
    .affiliateLink {
        background: transparent;
        padding: 0;
        p {
            background: #f5f5f5;
            padding: 25px;
            margin: 0;
            line-height: 22px !important;
            font-size: 0.95rem !important;
            max-width: 650px;
            margin: 0 auto;
            word-break: break-word;
            strong {
                text-transform: uppercase;
            }
        }
    }
}

.longform-header-container {
    padding-bottom: 2rem;
    .longform-full-width {
        .full-width-container {
            position: relative;
            display: block;
            .full-width-gradient {
                .full-width-content {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 5;
                    background: rgb(255, 255, 255);
                    background: linear-gradient(
                        180deg,
                        rgba(255, 255, 255, 0) 0%,
                        rgba(0, 0, 0, 1) 75%
                    );
                    padding: 150px 50px 50px;
                    @media (max-width: 1000px) {
                        padding: 0 20px;
                        background: none;
                        position: relative;
                    }
                    h1 {
                        font-family: $playfair;
                        font-size: 2.813rem;
                        font-weight: 400;
                        line-height: 2.813rem;
                        margin: 0;
                        padding: 0;
                        color: $white;
                        @media (max-width: 1000px) {
                            color: #000;
                        }
                        @media (max-width: 600px) {
                            font-size: 2rem;
                            line-height: 1;
                        }
                    }
                    p.series-tag {
                        text-transform: uppercase;
                        font-family: $opensans;
                        letter-spacing: 1px;
                        font-size: 1.15rem;
                        color: $white;
                        @media (max-width: 1000px) {
                            color: #000;
                        }
                        a {
                            font-weight: 700;
                            color: $white;
                            @media (max-width: 1000px) {
                                color: #000;
                            }
                        }
                    }
                    p.subtitle {
                        font-family: $opensans;
                        font-size: 1.4rem;
                        line-height: 1.3;
                        font-weight: 400;
                        margin: 21px 0 17px 0;
                        padding: 0;
                        color: $white;
                        @media (max-width: 1000px) {
                            color: #000;
                        }
                        @media (max-width: 600px) {
                            font-size: 1.1rem;
                        }
                    }
                }
                .mobile-heading {
                    display: none;
                }
                .desktop-heading {
                    display: block;
                    position: relative;
                    img {
                        width: 100%;
                        height: auto;
                        display: block;
                    }
                }
            }
        }
    }
    .caption {
        margin: 8px 0 2rem 0;
        padding: 0;
        color: #353535;
        font-family: $opensans;
        font-size: 0.9rem;
        line-height: 1.125;
        width: 50%;
        text-align: right;
        float: right;
        padding-right: 20px;
        @media (max-width: 1000px) {
            width: auto;
            padding: 0 20px;
            float: none;
            text-align: left;
        }
        a {
            text-decoration: underline;
            color: #353535;
            &:hover {
                text-decoration: none;
            }
        }
        .credit {
            margin: 0;
            padding: 0;
            color: #808080;
            font-family: $opensans;
            font-size: 0.75rem;
            line-height: 1.125;
            a {
                text-decoration: none !important;
                color: #7d7d7d;
                border-bottom: 2px solid #7d7d7d;
                &:hover {
                    text-decoration: none;
                    border-bottom: 0;
                }
            }
        }
    }

    .longform-columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0;
        align-items: center;
        border-bottom: 1px solid #858585;

        @media (max-width: 1000px) {
            grid-template-columns: 1fr;
        }

        .longform-column {
            .mobile-heading {
                display: none;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .desktop-heading {
                display: block;
                img {
                    width: 100%;
                    height: auto;
                    display: block;
                }
            }
            &.with-text {
                width: 75%;
                margin: 0 auto;
                text-align: center;
                align-items: center;

                @media (max-width: 600px) {
                    width: auto;
                    padding: 0 20px;
                }

                h1 {
                    font-family: $playfair;
                    font-size: 2.813rem;
                    font-weight: 400;
                    line-height: 2.813rem;
                    margin: 0;
                    padding: 0;

                    @media (max-width: 600px) {
                        font-size: 2rem;
                        line-height: 1;
                    }
                }

                p.series-tag {
                    text-transform: uppercase;
                    font-family: $opensans;
                    letter-spacing: 1px;
                    font-size: 1.15rem;

                    a {
                        font-weight: 700;
                    }
                }

                p.subtitle {
                    font-family: $opensans;
                    font-size: 1.4rem;
                    line-height: 1.3;
                    font-weight: 400;
                    margin: 21px 0 17px 0;
                    padding: 0;

                    @media (max-width: 600px) {
                        font-size: 1.1rem;
                    }
                }
            }
        }

        figcaption.caption {
            @media (max-width: 1000px) {
                width: auto;
                margin-bottom: 1rem;
                padding: 0 10px;
                text-align: left;
            }
        }
    }
}

.main-content {
    &.longform {
        font-family: $opensans;
        font-size: 1.1rem;
        line-height: 1.7;
        display: grid;
        grid-template-columns: 876px 1fr;
        gap: 30px;
        @media (max-width: 1000px) {
            display: block;
            &.article-fullwidth-right-column {
                display: none;
            }
        }
        h1 {
            font-family: $playfair;
            font-size: 2.813rem;
            font-weight: 400;
            line-height: 2.813rem;
            margin: 0;
            padding: 0;
        }
        h2 {
            font-family: $playfair;
            font-size: 2.375rem;
            font-weight: 400;
            line-height: 2.375rem;
            max-width: 650px;
            margin: 3.75rem auto 2rem auto;
            padding: 0;
        }
        h3 {
            font-family: $playfair;
            font-size: 1.625rem;
            font-weight: 700;
            line-height: 2rem;
            max-width: 650px;
            margin: 1em auto;
            padding: 0;
        }
        h4 {
        }
        h5 {
        }
        h6 {
        }
        ol,
        ul {
            font-family: $opensans;
            font-size: 1.1rem;
            line-height: 1.7;
            max-width: 650px;
            margin: 1em auto;
            a {
                text-decoration: underline;
                text-underline-offset: 2px;
                text-decoration-thickness: 2px;
                &:hover {
                    text-decoration: none;
                    color: $linkgrey;
                }
            }
        }
        ul {
            list-style-type: square;
        }
        .longform_share {
            max-width: 650px;
            margin: 0 auto;
        }
        hr {
            width: 186px;
            height: 3px;
            border: 0;
            text-align: center;
            margin: 15px auto 30px;
            background: #000;
            padding: 0;
        }
        p.subtitle {
            font-family: $opensans;
            font-size: 1.4rem;
            line-height: 1.3;
            font-weight: 400;
            margin: 21px 0 17px 0;
            padding: 0;
        }
        p {
            font-family: $opensans;
            font-size: 1.1rem;
            line-height: 1.7;
            max-width: 650px;
            margin: 2rem auto;
            word-break: break-word;
            a {
                text-decoration: underline;
                text-underline-offset: 2px;
                text-decoration-thickness: 2px;
                &:hover {
                    text-decoration: underline;
                    text-underline-offset: 2px;
                    text-decoration-thickness: 2px;
                    color: $linkgrey;
                }
            }
        }
        img {
            width: 100%;
            height: auto;
            &.left {
                float: left;
                width: 50%;
                height: auto;
            }
            &.right {
                float: right;
                width: 50%;
                height: auto;
            }
        }
        blockquote {
            border-left: 6px solid $black;
            margin: 40px auto;
            padding: 0 0 0 50px;
            font-style: italic;
            font-weight: 600;
            line-height: 1.5;
            font-family: $opensans;
            font-size: 1rem;
            max-width: 620px;

            p {
                margin: 0;
                padding: 0;
                font-weight: 600;
                line-height: 1.5;
                font-size: 1rem;
            }
        }
        figure {
            margin: 0;

            &.article-image {
                padding: 20px 0;

                &.lead-article-image {
                    padding-bottom: 20px;
                    padding-top: 0;
                }

                &.left {
                    float: left;
                    width: 307px;
                    margin-right: 44px;
                    margin-top: 0;
                    padding-top: 7px;

                    @media (max-width: 600px) {
                        width: 100%;
                        margin-right: auto;
                        float: none;
                        display: block;
                    }
                }

                &.right {
                    float: right;
                    width: 307px;
                    margin-left: 44px;
                    margin-top: 0;
                    padding-top: 7px;

                    @media (max-width: 600px) {
                        width: 100%;
                        margin-left: auto;
                        float: none;
                        display: block;
                    }
                }

                &.left_diptych {
                    float: left;
                    width: 48%;
                    margin-right: 15px;
                    margin-top: 0;
                    padding-top: 7px;
                }

                &.right_diptych {
                    float: right;
                    width: 48%;
                    margin-left: 15px;
                    margin-top: 0;
                    padding-top: 7px;
                }

                &.left_diptych,
                &.right_diptych {
                    @media (max-width: 1100px) {
                        width: 47.5vw;
                        margin-left: 0;
                        margin-right: 0;
                    }
                    @media (max-width: 1000px) {
                        width: 47vw;
                    }
                    @media (max-width: 800px) {
                        width: auto;
                        float: none;
                    }
                }

                &.right_diptych + * {
                    clear: both;
                }
            }

            &.article-video {
                padding: 20px 0;
                .credit {
                    margin-top: 4px;
                }
            }
            &.video {
                padding: 20px 0;
                .credit {
                    margin-top: 4px;
                }
            }
            &.pullquote {
                font-family: $playfair;
                font-size: 2.2rem;
                line-height: 1;
                padding: 20px 0;
                &:after {
                    content: '';
                    display: block;
                    vertical-align: top;
                    background: $black;
                    width: 183px;
                    height: 3px;
                }
                p {
                    font-family: $playfair;
                    font-size: 2.2rem;
                    line-height: 1;
                    margin-top: 0;
                    margin-bottom: 23px;
                }
                &.left {
                    float: left;
                    width: 307px;
                    margin-right: 44px;
                    margin-top: 0;
                    padding-top: 7px;
                }
                &.right {
                    float: right;
                    width: 307px;
                    margin-left: 44px;
                    margin-top: 0;
                    padding-top: 7px;
                }
            }
            .caption {
                margin: 4px 0 0 0;
                padding: 0;
                color: #353535;
                font-family: $opensans;
                font-size: 0.9rem;
                line-height: 1.125;
                a {
                    text-decoration: underline;
                    color: #353535;
                    &:hover {
                        text-decoration: none;
                    }
                }
                .credit {
                    margin: 0;
                    padding: 0;
                    color: #808080;
                    font-family: $opensans;
                    font-size: 0.75rem;
                    line-height: 1.125;
                    a {
                        text-decoration: none !important;
                        color: #7d7d7d;
                        border-bottom: 2px solid #7d7d7d;
                        &:hover {
                            text-decoration: none;
                            border-bottom: 0;
                        }
                    }
                }
            }
        }

        .binding-box {
            margin: 30px auto;
            max-width: 800px;
        }
        aside {
            .binding-box {
                margin: 0;
            }
        }
        .article-line {
            border-bottom: 1px solid #808080;
            max-width: 650px;
            margin: 0 auto;
            padding-bottom: 40px;
            margin-bottom: 40px;
            hr {
                background: $black;
                width: 186px;
                height: 3px;
                border: 0;
                text-align: left;
                margin: 0 0 40px 0;
            }
        }
        .article-fullwidth-column {
            div#desktopInArticle-0.ad-slot {
                text-align: center;
                display: block !important;
            }
            /* magazine promo box */
            .embedly-retail,
            .amazon-associated-product {
                display: inline-grid;
                grid-template-columns: 150px 1fr;
                grid-gap: 25px;
                padding: 0 0 20px 20px;
                margin-top: -15px;
                width: 97%;
                @media (max-width: 400px) {
                    width: auto;
                    padding: 0 20px 20px 20px;
                    grid-template-columns: 1fr;
                }
                img {
                    width: 150px;
                    height: auto;
                }
                h3 {
                    font-family: $playfair;
                    font-weight: 300;
                    font-size: 1.7rem;
                    line-height: 1;
                    margin: 0;
                }
                p {
                    font-family: $opensans;
                    font-size: 0.95rem;
                    line-height: 1.3;
                }
                button {
                    background: $black;
                    border: 0;
                    color: $white;
                    text-transform: uppercase;
                    padding: 14px 65px;
                    font-weight: 600;
                    &:hover {
                        background: $linkgrey;
                    }
                }
            }
        }
        .author-box {
            max-width: 650px;
            margin: 0 auto;
            display: block;
            clear: both;
            &.by-line {
                width: 100%;
                .author-text {
                    padding-bottom: 5px;
                    p {
                        margin: 0;
                        padding: 0;
                        &.author {
                            font-weight: 700;
                            font-size: 0.95rem;
                            a {
                                border-bottom: 0;
                                text-decoration: none;
                                &:hover {
                                    text-decoration: underline;
                                    color: inherit;
                                }
                            }
                        }
                        &.photographer {
                            font-weight: 700;
                            font-size: 0.95rem;
                            a {
                                border-bottom: 0;
                                text-decoration: none;
                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                        &.author-short-bio {
                            font-size: 0.95rem;
                            line-height: 1;
                            padding-bottom: 4px;
                        }
                    }
                    time {
                        font-size: 0.95rem;
                        font-weight: 600;
                    }
                }
                &.single-author {
                    display: grid;
                    grid-template-columns: 66px 1fr;
                    grid-gap: 22px;
                }
                &.no-photo {
                    display: block !important;
                }
                &.footer-author-box {
                    display: grid;
                    grid-template-columns: 110px 1fr !important;
                    grid-gap: 22px;
                    max-width: 650px;
                    margin: 3rem auto 2rem;
                    .author-headshot {
                        img {
                            width: 110px;
                            height: 110px;
                            background: $black;
                            object-fit: cover;
                            border-radius: 50%;
                        }
                        &.arts-culture {
                            img {
                                background: $artsculture;
                            }
                        }
                        &.history {
                            img {
                                background: $history;
                            }
                        }
                        &.innovation {
                            img {
                                background: $innovation;
                            }
                        }
                        &.science,
                        &.science-nature {
                            img {
                                background: $science;
                            }
                        }
                        &.smart-news {
                            img {
                                background: $smartnews;
                            }
                        }
                        &.smithsonian-institution,
                        &.at-the-smithsonian {
                            img {
                                background: $smithsonian;
                            }
                        }
                        &.travel {
                            img {
                                background: $travel;
                            }
                        }
                    }
                    .author-smaller {
                        font-size: 0.85rem;
                        text-transform: none;
                        .fab {
                            background: $black;
                            color: $white;
                            object-fit: cover;
                            border-radius: 50%;
                            font-size: 0.7rem;
                            padding: 5px;
                        }
                    }
                    .author-bio-text {
                        font-size: 0.95rem;
                        line-height: 1.5;
                        border-bottom: 3px solid #000;
                        padding-bottom: 15px;
                        a {
                            text-decoration: none;
                            border-bottom: 0;
                        }
                    }
                }
            }
            .author-headshot {
                img {
                    width: 66px;
                    height: 66px;
                    background: $black;
                    object-fit: cover;
                    border-radius: 50%;
                }
                &.arts-culture {
                    img {
                        background: $artsculture;
                    }
                }
                &.history {
                    img {
                        background: $history;
                    }
                }
                &.innovation {
                    img {
                        background: $innovation;
                    }
                }
                &.science,
                &.science-nature {
                    img {
                        background: $science;
                    }
                }
                &.smart-news {
                    img {
                        background: $smartnews;
                    }
                }
                &.smithsonian-institution,
                &.at-the-smithsonian {
                    img {
                        background: $smithsonian;
                    }
                }
                &.travel {
                    img {
                        background: $travel;
                    }
                }
            }
        }
        .article-comments {
            max-width: 650px;
            margin: auto;
        }
        .btn-comments {
            margin: 25px 0 0;
            padding-left: 0;
            background: none;
            border: none;
            text-align: left;
            display: block;
            font-size: 1rem;
            font-weight: 700;
            text-transform: uppercase;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
                text-decoration-thickness: 2px;
            }
            a {
                color: $white;
                padding: 5px;
                border: 0;
                font-family: $playfair;
                font-size: 1.5rem;
                font-weight: 700;
                text-transform: uppercase;
                text-align: center;
                margin: 0 auto;
                &:hover {
                    text-decoration: none;
                }
            }
        }

        .tag-list {
            font-weight: 600;
            font-size: 0.85rem;
            margin: 0 auto;
            max-width: 730px;
            nav {
                a {
                    font-weight: 400;
                    font-size: 0.85rem;
                }
            }
        }
    }
    &.article-list {
        max-width: 1100px;
        width: auto;
        margin: 0 auto;

        @media (max-width: 1100px) {
            width: auto;
        }

        &.tag-list {
            h2 {
                border-bottom: 3px solid $black;
                font-family: $playfair;
                font-size: 2.75rem;
                font-weight: 300;
                line-height: 1.5;
            }
        }
        &.category-list {
            h2 {
                border-bottom: 3px solid $black;
                font-family: $playfair;
                font-size: 2.75rem;
                font-weight: 300;
                line-height: 1.5;
                margin-bottom: 0;
            }
            nav {
                border-bottom: 1px solid $grey;
                margin: 0 0 30px 0;
                padding: 18px 0;
                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                    li {
                        display: inline-block;
                        padding: 0;
                        margin: 0;
                        font-family: $opensans;
                        text-transform: uppercase;
                        font-weight: 700;
                        font-size: 0.95rem;
                        padding: 0 20px;
                        &:first-of-type {
                            padding-left: 0;
                        }
                        &:last-of-type {
                            padding-right: 0;
                        }
                        a {
                            border-bottom: 0;
                        }
                    }
                }
            }
        }
        .article-columns {
            display: grid;
            grid-template-columns: 1fr 300px;
            grid-gap: 46px;
            .load-btn {
                background: $black;
                display: block;
                color: $white;
                margin: 0 auto;
                text-align: center;
                padding: 5px;
                border: 0;
                &:hover {
                    background: #666;
                }
                a {
                    color: $white;
                    padding: 5px;
                    border: 0;
                    font-family: $playfair;
                    font-size: 1.5rem;
                    font-weight: 700;
                    text-transform: uppercase;
                    text-align: center;
                    margin: 0 auto;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
            .article-container {
                .article-list-item {
                    display: grid;
                    grid-template-columns: 250px 1fr;
                    grid-gap: 30px;
                    border-bottom: 1px solid #ccc;
                    padding: 30px 0;
                    &:last-child {
                        border: 0;
                    }
                    &:first-child {
                        padding-top: 0;
                    }
                    .article-list-image {
                        img {
                            width: 250px;
                            height: auto;
                        }
                    }
                    .article-list-text {
                        display: inline-block;
                        vertical-align: middle;
                        h3 {
                            font-family: $playfair;
                            font-weight: normal;
                            padding: 13px 0;
                            margin: 0;
                            font-size: 1.7rem;
                            line-height: 1.1;
                        }
                        .article-series {
                            padding: 0;
                            margin: 0 0 4px 0;
                            text-transform: uppercase;
                            font-weight: 600;
                            font-family: $opensans;
                            font-size: 0.95rem;
                            letter-spacing: 1px;
                        }
                        .article-dek {
                            font-family: $opensans;
                            padding: 0;
                            margin: 7px 0 0 0;
                            font-size: 0.95rem;
                        }
                        .article-date {
                            display: block;
                        }
                        .article-author {
                            font-family: $opensans;
                            font-weight: 700;
                            font-size: 0.9rem;
                            margin: 9px 0 0 0;
                            padding: 0;
                            a {
                                border-bottom: 0;
                                text-decoration: none;
                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                        .article-published {
                            font-family: $opensans;
                            font-size: 0.9rem;
                            margin: 9px 0 0 0;
                            padding: 0;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}

.sponsored {
    footer {
        margin-top: 0;
    }
    .sponsored-heading {
        background: #f4f4f4;
        text-align: center;
        padding: 20px 0;
        .sponsored-text {
            font-family: $opensans;
            text-transform: uppercase;
            letter-spacing: 1px;
            color: $black;
            font-weight: 600;
            font-size: 0.9rem;
        }
        .sponsored-logo {
            text-align: center;
            margin-top: 10px;
            img {
                height: 75px;
                width: auto;
                @media (max-width: 600px) {
                    max-width: 90%;
                    height: auto;
                }
            }
        }
    }
    .sponsored-notice {
        background: #f4f4f4;
        text-align: center;
        margin-top: 3.25rem;
        button {
            width: 11rem;
            padding: 0.5rem 0;
            margin-left: 15px;
            border: 2px solid $black;
            color: $black;
            font-family: $opensans;
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: 1px;
            position: relative;
            top: -4px;
            &:hover {
                background: $black;
            }
            a {
                display: block;
                &:hover {
                    color: $white;
                    text-decoration: none;
                }
            }
        }
        p {
            margin: 0;
            font-family: $playfair;
            font-size: 1.625rem;
            font-weight: 700;
            padding: 70px 0;
            &.editorial {
                border-top: 1px solid #d9d9d9;
                font-family: $opensans;
                font-size: 1rem;
                padding: 20px 0;
                font-weight: 400;
            }
        }
    }
}

.article-special-wrap {
    background: $black;
    color: $white;
    .article-special {
        width: auto;
        max-width: 1000px;
        margin: 0 auto;
        padding: 15px 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        @media (max-width: 800px) {
            grid-template-columns: 1fr;
            gap: 10px;
        }
        h1,
        p {
            margin: 0;
            padding: 0;
            line-height: 1.2;
            &.hed {
                font-family: $playfair;
                font-weight: 800;
                text-transform: uppercase;
                font-size: 1.9rem;
                margin: 0;
                letter-spacing: 2px;
            }
            &.dek {
                font-family: $opensans;
                padding-bottom: 5px;
                font-size: 0.9rem;
            }
        }
        a {
            color: $white;
        }
        .article-special-logo {
            justify-self: right;
            @media (max-width: 800px) {
                justify-self: start;
            }
            p {
                color: #d3d3d3;
                text-transform: uppercase;
                font-family: $opensans;
                font-size: 0.7rem;
            }
        }
    }
}
