.delay.flatpage {
    .blue-section-heading {
        background: #133155;
        position: relative;
        display: block;
        padding: 10px;
        margin: -3px 0 0 0;
        @media (max-width: 600px) {
            padding: 0 0 20px 0;
        }
        .blue-container {
            width: 1024px;
            margin: 0 auto;
            display: grid;
            grid-template-columns: 380px 1fr;
            grid-gap: 70px;
            align-items: center;
            @media (max-width: 600px) {
                width: 100%;
                display: block;
                text-align: center;
            }
            h1 {
                color: $white;
                font-size: 2.3rem;
                line-height: 2.8rem;
            }
            p {
                color: $white;
                font-family: $opensans;
                font-size: 1.3rem;
                margin-top: 5px;
                @media (max-width: 600px) {
                    width: 90%;
                    margin: 0 auto;
                }
            }
            .blue-image {
                img {
                    width: 362px;
                    height: auto;
                    @media (max-width: 600px) {
                        width: 70%;
                        height: auto;
                        text-align: center;
                        position: relative;
                        z-index: 4;
                    }
                }
            }
            button.brown {
                background: #e4d658;
                border: 0;
                @media (max-width: 600px) {
                    display: block;
                    display: block;
                    width: 90%;
                    margin: 20px auto;
                    text-align: center;
                }
                a {
                    font-family: $opensans;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-weight: 700;
                    font-size: 0.8rem;
                    padding: 5px;
                    display: block;
                    width: 120px;
                    @media (max-width: 600px) {
                        width: 100%;
                    }
                }
            }
            button.blue {
                background: #5ad4fb;
                border: 0;
                margin-left: 20px;
                @media (max-width: 600px) {
                    display: block;
                    width: 90%;
                    margin: 20px auto 20px auto;
                    text-align: center;
                }
                a {
                    font-family: $opensans;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-weight: 700;
                    font-size: 0.8rem;
                    padding: 5px;
                    display: block;
                    width: 120px;
                    @media (max-width: 600px) {
                        width: 100%;
                    }
                }
            }
        }
        .brown-left {
            position: absolute;
            left: -20px;
            width: 100px;
            height: auto;
            @media (max-width: 600px) {
                display: none;
            }
        }
        .blue-left {
            position: absolute;
            left: -20px;
            top: 120px;
            width: 100px;
            height: auto;
            @media (max-width: 600px) {
                display: none;
            }
        }
        .brown-right {
            position: absolute;
            right: 0;
            width: 100px;
            height: auto;
            top: 170px;
            @media (max-width: 600px) {
                display: none;
            }
        }
    }
    hr {
        border: 0;
        border-top: 1px solid #ccc;
        margin: 60px 0;
    }
    .gift-container {
        background: #f6f6f6;
        padding: 50px 0;
        margin-bottom: 80px;
        @media (max-width: 600px) {
            padding: 0;
        }
        .gift-container-container {
            width: 730px;
            background: #fff;
            padding: 30px;
            margin: 0 auto;
            display: grid;
            grid-template-columns: 215px 1fr;
            grid-gap: 50px;
            align-items: center;
            text-align: center;
            @media (max-width: 600px) {
                width: 90%;
                display: block;
                text-align: center;
                padding: 30px 0;
                margin: 0 auto;
                background: transparent;
            }
            h2 {
                font-weight: 900;
                color: #133155;
                font-size: 2.3rem;
                margin-bottom: 0;
            }
            p {
                font-family: $opensans;
                font-size: 1.2rem;
                line-height: 2rem;
                &.uppercase {
                    text-transform: uppercase;
                    font-family: $opensans;
                    letter-spacing: 1px;
                    margin-top: 0;
                    font-size: 1.2rem;
                }
            }
            button.brown {
                background: #e4d658;
                border: 0;
                @media (max-width: 600px) {
                    display: block;
                    width: 90%;
                    margin: 20px auto 0 auto;
                    text-align: center;
                }
                a {
                    font-family: $opensans;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-weight: 700;
                    font-size: 0.8rem;
                    padding: 5px;
                    display: block;
                    width: 120px;
                    @media (max-width: 600px) {
                        width: 100%;
                    }
                }
            }
            .gift-image {
                img {
                    width: 215px;
                    height: auto;
                    @media (max-width: 600px) {
                        width: 80%;
                        height: auto;
                    }
                }
            }
        }
    }
}
.virtual-events.flatpage {
    .introduction {
        background: $black;
        color: $white;
        padding: 50px 0;
        position: relative;
        top: -2px;
        h1 {
            font-size: 3rem;
            margin: 0;
            padding: 0;
            font-weight: 400;
        }
        p {
            margin-top: 0;
            font-family: $opensans;
            font-size: 1rem;
            line-height: 1.9rem;
            width: 75%;
            @media (max-width: 600px) {
                width: 100%;
            }
        }
        a {
            color: $white;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        .container {
            @media (max-width: 600px) {
                padding: 0 30px;
                text-align: center;
            }
        }
    }
    .container {
        max-width: $desktop_default;
        margin: 0 auto;
        @media (max-width: 600px) {
            padding: 0;
        }
    }
    .featured-event {
        background: #f9f9f9;
        position: relative;
        top: -1px;
        .container {
            .featured-event-item {
                display: grid;
                grid-template-columns: 460px 1fr;
                gap: 120px;
                align-items: center;
                padding: 75px 0;
                @media (max-width: 600px) {
                    display: block;
                    padding: 50px 20px;
                }
                .image {
                    img {
                        width: 100%;
                        height: auto;
                        box-shadow: 3px 5px 8px 0px #a3a3a3;
                        @media (max-width: 600px) {
                            margin-bottom: 20px;
                            box-shadow: 0px 0px 0px 0px;
                        }
                    }
                }
                .featured-event-item-text {
                    h3 {
                        font-size: 1.938rem;
                        font-weight: 400;
                        margin: 10px 0 0 0;
                        padding: 0;
                    }
                    p {
                        font-size: 1.1rem;
                        font-family: $opensans;
                        line-height: 1.8rem;
                        &.timestamp {
                            font-weight: 700;
                            font-size: 0.95rem;
                        }
                        a {
                            text-decoration: underline;
                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                    button {
                        background: $white;
                        border: 0;
                        font-family: $opensans;
                        font-weight: 700;
                        margin: 0;
                        padding: 0;
                        margin-top: 20px;
                        @media (max-width: 600px) {
                            width: 100%;
                        }
                        a {
                            letter-spacing: 1px;
                            font-size: 0.85rem;
                            text-transform: uppercase;
                            display: block;
                            color: $white;
                            padding: 12px 40px;
                            background: $black;
                            &:hover {
                                background: $gray;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
    }
    .event-list {
        .container {
            max-width: $desktop_default;
            margin: 0 auto;
            @media (max-width: 600px) {
                padding: 0 20px 30px 20px;
            }
            .past-events {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                align-items: center;
                padding-bottom: 5px;
                border-bottom: 5px solid $black;
                margin: 50px 0 35px 0;
                h2 {
                    margin: 0;
                    padding: 0;
                    font-family: $opensans;
                    font-weight: 700;
                    letter-spacing: 1px;
                    font-size: 0.85rem;
                    text-transform: uppercase;
                }
                .follow {
                    text-align: right;
                    img {
                        width: 169px;
                        height: auto;
                    }
                }
            }
            .event-container {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 40px;
                @media (max-width: 600px) {
                    display: block;
                }
                .event-list-item {
                    position: relative;
                    @media (max-width: 600px) {
                        margin-bottom: 50px;
                    }
                    img {
                        width: 100%;
                        height: auto;
                        position: relative;
                        margin-bottom: 10px;
                    }
                    .overlay {
                        position: absolute;
                        top: 20px;
                        right: 0;
                        background: $white;
                        line-height: 0.85rem;
                        margin: 0;
                        padding: 9px 30px;
                        font-family: $opensans;
                        font-weight: 700;
                        letter-spacing: 1px;
                        font-size: 0.85rem;
                        text-transform: uppercase;
                    }
                    h3 {
                        margin: 10px 0 0 0;
                        padding: 0;
                        font-weight: 400;
                    }
                    p {
                        font-family: $opensans;
                        font-size: 0.95rem;
                        margin-top: 12px;
                    }
                }
            }
        }
    }
}

.contact-page {
    &.flatpage {
        &.acoustic {
            .preference-email {
                display: block;
                font-size: 1.9rem;
            }
            .messages {
                list-style-type: none;
                margin: 0 0 20px 0;
                padding: 0;
                font-weight: 600;
                .info {
                    background: #fff3cd;
                    padding: 5px 10px;
                    a {
                        text-decoration: underline;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
            form {
                width: 100%;
                .tinytext {
                    font-size: 0.9rem;
                    a {
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
                .centeritem {
                    text-align: center;
                    margin: 0;
                    padding: 0;
                    &.submit {
                        padding-top: 20px;
                    }
                    &.tinytext {
                        font-size: 0.9rem;
                    }
                    &.email-lookup {
                        margin-top: 40px;
                    }
                }
                input[type='submit'] {
                    background: $black;
                    color: $white;
                    border: 0;
                    padding: 15px 20px;
                    text-transform: uppercase;
                    font-weight: 700;
                    text-align: center;
                    letter-spacing: 1px;
                    position: relative;
                }
            }
            .emaillookup {
                background: #f6f6f6;
                margin: 40px 0;
                p {
                    padding: 5px 10px;
                }
            }
            .siAllSaved {
                background: #f6f6f6;
                padding: 10px;
                font-size: 0.9rem;
                margin-bottom: 70px;
                clear: both;
            }
            hr {
                margin-bottom: 30px;
                border: 0;
                border-top: 1px solid #ccc;
            }
            .preferenceGrid {
                margin-bottom: 30px;
                input[type='email'] {
                    padding: 7px 5px;
                    width: 98%;
                    display: block;
                    font-size: 1.2rem;
                }
                input[type='checkbox'] {
                    display: inline;
                    width: 20px;
                    height: 20px;
                    position: relative;
                    top: 3px;
                }
                label {
                    display: inline;
                    font-size: 1.3rem;
                    font-weight: 700;
                }
                p {
                    margin-left: 32px;
                }
            }
        }
    }
}

.rss-table {
    display: table;
    width: 100%;
}
.rss-row {
    display: table-row;
    font-family: $opensans;
    width: 100%;
    clear: both;
}
.rss-cell {
    float: left; /* fix for  buggy browsers */
    display: table-column;
    width: 50%;
    border-top: 1px solid #ccc;
    padding: 5px 0;
    &.no-top {
        border-top: 0;
    }
    &:last-of-type {
        text-align: right;
    }
}
