/* universal widget heading */
.widget-heading {
    margin: 2rem 0 2rem;
    padding: 15px;
    border-top: 3px solid $black;
    border-left: 3px solid $black;
    font-family: $opensans;
    font-size: 1.2rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 2px;
    font-weight: 700;
    height: 0;
}

.widget-section {
    line-height: 0.85rem;
    margin: 0;
    padding: 0;
    font-family: $opensans;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 0.85rem;
    text-decoration: none;
    &.upper {
        text-transform: uppercase;
    }
    a {
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        letter-spacing: 1px;
        font-size: 0.85rem;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

/* setting the in-article pixel field to a 1x1 */
.widget-article-pixel {
    img {
        width: 1px !important;
        height: 1px !important;
    }
}

/* featured stories: list selection */
.widget-generic-article-list {
    .widget-article-list-item {
        display: grid;
        grid-template-columns: 250px 1fr;
        grid-gap: 30px;
        align-items: center;
        margin: 2rem 0;
        border-bottom: 1px solid #cccccc;
        padding: 0 0 2rem 0;
        &:first-of-type {
            margin-top: 0;
            border-top: 0;
        }
        &:last-of-type {
            margin-bottom: 0;
            border-bottom: 0;
            padding-bottom: 0;
        }
        .widget-item-image {
            img {
                width: 100%;
                height: auto;
                display: block;
            }
        }
        .widget-item-text {
            .section {
                max-width: 100%;
                margin: 0;
                a {
                    text-decoration: none;
                }
            }
            h3 {
                max-width: 100%;
                margin: 1rem auto;
                font-weight: 300;
                a {
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            .article-author {
                font-family: $opensans;
                line-height: 1.7;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 0.95rem;
                margin: 0;
                a {
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

/* call to action plugin: used in featured stories and grids */
.widget-call-to-action-small {
    padding: 15px;
    border: 3px solid $black;
    position: relative;
    z-index: 2;
    text-align: left;
    &:before,
    &:after {
        content: '';
        width: 100px;
        height: 100px;
        background-color: $white;
        transform: rotate(45deg);
        position: absolute;
        top: -20px;
        right: -20px;
        display: block;
        z-index: 1;
    }
    &:after {
        top: inherit;
        left: inherit;
        left: -20px;
        bottom: -20px;
    }
    .section {
        position: relative;
        z-index: 3;
        text-transform: uppercase;
        margin: 1rem 0 0 0;
    }
    .secondary {
        position: relative;
        z-index: 3;
        font-family: $opensans;
        font-weight: 700;
        font-size: 0.85rem;
        text-decoration: none;
        margin: 2px 0;
    }
    h3 {
        font-weight: 400;
        font-size: 1.5rem;
        margin-top: 0;
        line-height: 1.75rem;
        margin-bottom: 8px;
        position: relative;
        z-index: 3;
    }
    img {
        width: 100%;
        height: auto;
        display: block;
        position: relative;
        z-index: 3;
    }
}

/* call to action plugin: se right rail promotion */
/* need to revisit this */
.widget-call-to-action-se-promo {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-gap: 25px;
    align-items: center;
    img {
        width: 100%;
        height: auto;
        display: block;
    }
    h3 {
        font-weight: 400 !important;
        margin-top: 0;
        font-size: 1.2rem !important;
    }
    button {
        border: 3px solid $black;
        background: $black;
        color: $white;
        &:hover {
            background: $white;
            color: $black;
            a {
                color: $black;
            }
        }
        a {
            color: $white;
            text-transform: uppercase;
            font-family: $opensans;
            font-weight: 700;
            letter-spacing: 1px;
            display: block;
            padding: 0.4rem 2rem;
            &:hover {
                text-decoration: none;
            }
        }
    }
}

/* most popular: right rail */
.widget-most-popular {
    padding: 0 20px 20px;
    &.larger {
        margin-top: 0;
    }
    ol {
        margin: 0 0 0 15px;
        padding: 0;
        line-height: inherit;
        li {
            padding-bottom: 10px;
            &:last-of-type {
                padding-bottom: 0;
            }
            &::marker {
                font-family: $playfair;
                font-weight: 300;
                font-size: 2.5rem;
                vertical-align: top;
                position: relative;
                line-height: 1;
            }
            .containment {
                display: inline-grid;
                grid-template-columns: 76px 1fr;
                grid-gap: 15px;
                vertical-align: middle;
                padding: 5px 0;
                .thumb {
                    width: 76px;
                    height: 76px;
                    img {
                        width: 100%;
                    }
                }
                .headline {
                    font-weight: 400;
                    margin: 0;
                    padding: 0;
                    line-height: 1.3;
                    font-size: 0.9rem;
                    font-family: $opensans;
                    a {
                        border: 0;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

/* blog stream: list with no images */
/* there are a lot of article styles that force many important tags */
/* need to revisit this */
.widget-blog-list-no-images {
    padding: 0 0 5px 20px;

    .title {
        font-weight: 400;
        line-height: 1.35rem !important;
        margin: 4px 0 22px 0;
        padding: 0;
        font-size: 1.17rem !important;
        font-family: $playfair !important;
        a {
            text-decoration: none !important;
            &:hover {
                text-decoration: underline !important;
                color: $black !important;
            }
        }
    }
    hr {
        border: 0;
        background: transparent !important;
        width: auto !important;
        height: auto !important;
        text-align: left !important;
        margin: 0 !important;
        &:before {
            background: #000;
            height: 2px;
            width: 60%;
            content: '';
            display: inline-block;
            vertical-align: top;
        }
    }
    ul {
        list-style-type: none;
        margin-left: 0;
        padding-left: 0;
        li {
            list-style-type: none;
            font-family: $opensans;
            padding-bottom: 8px;
            a {
                text-decoration: none;
                &:hover {
                    text-decoration: none !important;
                    border-bottom: 0;
                    text-decoration-thickness: 0 !important;
                }
            }
        }
    }
}

.widget-blog-wrapper {
    max-width: 1100px;
    width: auto;
    margin: 0 auto;
    padding: 0 20px;
}

/* blogs: main screen featured items */
.widget-featured-blogs {
    margin-top: 2rem;
    list-style-type: none;
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-gap: 30px;
    border-bottom: 1px solid #ccc;
    @media (max-width: 800px) {
        display: block;
    }
    @media (max-width: 600px) {
        margin-top: 10px;
    }
    .widget-featured-blogs-item {
        @media (max-width: 800px) {
            margin-bottom: 50px;
        }
        h3 {
            margin: 0.75rem 0 0 0;
            font-weight: 300;
        }
        p.widget-section {
            margin: 1.5rem 0 0;
        }
        &:first-child {
            grid-column: 1;
            grid-row: 1 / last-line;
            grid-row-start: span 3;
            position: relative;
            z-index: 3;
            h3 {
                font-family: $playfair;
                font-size: 2.25rem;
                font-weight: 300;
                margin: 1rem 0 0 0;
                line-height: 2.5rem;
                @media (max-width: 600px) {
                    font-size: 2rem;
                    line-height: 1;
                }
            }
            p.widget-section {
                margin: 1.5rem 0 1rem;
            }
            p.article-dek {
                font-family: $opensans;
                font-size: 1.2rem;
                @media (max-width: 600px) {
                    font-size: 1.1rem;
                }
            }
            p.author {
                font-family: $opensans;
                line-height: 1.7;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 0.95rem;
            }
        }
    }

    img {
        width: 100%;
        height: auto;
    }
}

/* widget for two column pages (right rail) */
.widget-page-right-column {
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-gap: 30px;
    @media (max-width: 800px) {
        grid-template-columns: 1fr;
    }
    .widget-blog-posting-list-largest {
        border-bottom: 1px solid #ccc;
        padding: 30px 0px;
        .widget-blog-list-text {
            h3 {
                font-family: $playfair;
                font-weight: 300;
                padding: 13px 0;
                margin: 0;
                font-size: 2.25rem;
                line-height: 1.1;
            }
            .dek {
                font-family: $opensans;
                padding: 0;
                margin: 7px 0 0 0;
                font-size: 0.95rem;
            }
            .author {
                font-family: $opensans;
                line-height: 1.7;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 0.95rem;
            }
            .published {
                font-family: 'Open Sans', sans-serif;
                padding: 0;
                margin: 7px 0 0 0;
                font-size: 0.95rem;
            }
        }
        .widget-blog-list-image {
            img {
                width: 100%;
                height: auto;
                display: block;
            }
        }
    }
    .widget-blog-posting-list {
        display: grid;
        grid-template-columns: 250px 1fr;
        gap: 30px;
        border-bottom: 1px solid #ccc;
        padding: 30px 0px;
        align-items: center;
        @media (max-width: 600px) {
            grid-template-columns: 1fr;
        }
        &:last-of-type {
            border-bottom: 0;
        }
        .widget-blog-post-text {
            h3 {
                font-family: $playfair;
                font-weight: 300;
                padding: 13px 0;
                margin: 0;
                font-size: 1.7rem;
                line-height: 1.1;
            }
            .dek {
                font-family: $opensans;
                padding: 0;
                margin: 7px 0 0 0;
                font-size: 0.95rem;
            }
            .author {
                font-family: $opensans;
                line-height: 1.7;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 0.95rem;
            }
            .published {
                font-family: 'Open Sans', sans-serif;
                padding: 0;
                margin: 7px 0 0 0;
                font-size: 0.95rem;
            }
        }
        .widget-blog-post-image {
            img {
                width: 100%;
                height: auto;
                display: block;
            }
        }
    }
}
