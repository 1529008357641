@media print {
    .headerBar,
    .ad-slot,
    .advertisement,
    .ad-wrapper,
    .CampaignType--floating,
    iframe,
    .video,
    .slideshow-wrapper .slideshow .slide-info .slide-controls,
    .twitter-tweet,
    .binding-box,
    .articleRight,
    .main-content.longform .author-box.by-line .author-text p.author .separator,
    .main-content.longform
        .author-box.by-line
        .author-text
        p.author
        .fa-twitter,
    .main-content.longform
        .author-box.by-line.footer-author-box
        .author-smaller,
    .main-content.article .author-box.by-line .author-text p.author .separator,
    .main-content.article .author-box.by-line .author-text p.author .fa-twitter,
    .main-content.article
        .author-box.by-line
        .author-text
        p.author
        .author-smaller,
    .recommended-videos,
    .article-comments,
    .fs-sticky-footer,
    footer,
    .main-content.longform
        .article-fullwidth-column
        div#desktopInArticle-0.ad-slot {
        display: none !important;
    }
    .main-content.longform p a,
    .main-content.article p a {
        text-decoration: none;
    }
    .main-content.article figure .caption .credit a {
        border-bottom: none;
    }
    .main-content.article .article-columns {
        grid-template-columns: 1fr;
    }
    .slideshow-wrapper .slideshow .slideshow-slides .slide {
        margin-bottom: 50px;
        display: block !important;
    }
}
