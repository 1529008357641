.issue-page {
    .main-content {
        width: auto;
        max-width: 1100px;
        padding: 0 20px;
    }

    .issue-cover-wrapper {
        width: 450px;
        max-width: 100%;
        margin: 64px auto;
        padding-bottom: 48px;
        border: 3px solid #000;
        position: relative;
        text-align: center;

        &:before,
        &:after {
            content: '';
            width: 100px;
            height: 100px;
            background-color: #fff;
            position: absolute;
            top: -5px;
            right: -5px;
            z-index: 1;
        }

        &:after {
            top: unset;
            left: -5px;
            right: unset;
            bottom: -5px;
        }

        .current-issue {
            margin: 40px auto 0;
            text-align: center;
            font-family: $opensans;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 1;
            position: relative;
            z-index: 2;
        }

        h1 {
            margin: 12px auto 32px;
            font-size: 2.2rem;
            text-align: center;
            line-height: 1;
            position: relative;
            z-index: 2;
        }

        .issue-cover img {
            width: 350px;
            max-width: 100%;
            position: relative;
            z-index: 2;

            @media (max-width: 600px) {
                max-width: 80%;
            }
        }
    }

    .issue-articles {
        margin-top: 64px;
        display: grid;
        grid-template-columns: 5fr 2fr;
        gap: 40px;

        @media (max-width: 1100px) {
            grid-template-columns: 4fr 2fr;
        }

        @media (max-width: 800px) {
            grid-template-columns: 1fr;
        }

        .article-list {
            border-bottom: none;

            h2 {
                height: 0;
                margin: 0 0 10px;
                padding: 15px;
                border-top: 4px solid #000;
                border-left: 4px solid #000;
                font-family: $opensans;
                font-size: 1.2rem;
                letter-spacing: 1px;
                text-transform: uppercase;
                text-decoration: underline;
                text-decoration-thickness: 2px;
                text-underline-offset: 2px;
            }

            .article-wrapper {
                padding: 32px 0;
                border-top: 1px solid #ececec;

                @media (max-width: 800px) {
                    display: grid;
                    grid-template-columns: 1fr 3fr;
                }

                @media (max-width: 600px) {
                    grid-template-columns: 1fr;
                }

                &:first-of-type {
                    border: none;
                }

                .feature-thumbnail {
                    width: 240px;
                    vertical-align: middle;

                    @media (max-width: 600px) {
                        width: 100%;
                        margin-bottom: 20px;
                    }
                }

                .text-wrapper {
                    max-width: 400px;
                    margin-left: 28px;
                    display: inline-block;
                    vertical-align: middle;

                    @media (max-width: 1100px) {
                        max-width: 340px;
                    }

                    @media (max-width: 1000px) {
                        max-width: 200px;
                    }

                    @media (max-width: 800px) {
                        max-width: none;
                    }

                    @media (max-width: 800px) {
                        margin-left: 0;
                    }

                    h3 {
                        margin: 0 0 5px;
                        font-size: 1.5rem;
                        font-weight: normal;
                    }

                    p {
                        margin: 5px 0;
                        font-size: 0.9rem;
                        font-family: $opensans;
                    }

                    .byline {
                        margin-top: 10px;
                        font-family: $opensans;
                        font-size: 0.9rem;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                }
            }

            .article-wrapper.is-sidebar {
                margin-left: 60px;
                padding-top: 0;
                border: none;
                position: relative;

                &:before {
                    content: '\21b3';
                    color: #000;
                    font-size: 3rem;
                    position: absolute;
                    top: -25px;
                    left: -65px;
                }
            }
        }

        .department-list {
            h2 {
                margin-bottom: -6px;
                text-decoration: none;
            }

            .article-wrapper {
                padding: 0;
                border: none;

                @media (max-width: 800px) {
                    display: block;
                }

                .text-wrapper {
                    margin: 5px 0 5px 18px;
                    padding-top: 10px;

                    &:before {
                        content: '';
                        width: 153px;
                        height: 2px;
                        margin-bottom: 24px;
                        background-color: #000;
                        display: block;
                    }

                    .department {
                        font-family: $opensans;
                        font-size: 0.9rem;
                        font-weight: bold;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                    }

                    h3 {
                        margin: 0;
                        font-size: 1.3rem;
                        font-weight: normal;
                    }
                }
            }

            .article-wrapper.is-sidebar {
                margin-left: 50px;
                padding-top: 5px;
                border: none;
                position: relative;

                &:before {
                    top: 0;
                    left: -37px;
                }

                .text-wrapper:before {
                    display: none;
                }
            }
        }
    }
}

.issue-archive {
    h1 {
        margin-top: 55px;
        border-bottom: 4px solid #000;
        font-size: 2.8rem;
        font-weight: normal;
        line-height: 65px;
    }

    h2 {
        margin: 28px 0 20px;
        font-size: 2.3rem;
        font-weight: normal;
    }

    .year-nav {
        margin-top: 32px;
        font-family: $opensans;

        .filter {
            margin-right: 10px;
            font-size: 1.1rem;
            display: inline-block;
        }

        .year-select {
            .select-nav {
                padding: 8px 15px;
            }
        }
    }

    .archive-list {
        padding: 0;
        list-style-type: none;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 32px;

        @media (max-width: 1200px) {
            grid-template-columns: repeat(4, 1fr);
        }

        @media (max-width: 800px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width: 600px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 400px) {
            grid-template-columns: repeat(1, 1fr);
        }

        .issue {
            margin-bottom: 10px;

            img {
                width: 100%;
            }

            .issue-month {
                margin: 5px 0;
                font-family: $opensans;
                text-align: center;
                text-transform: uppercase;
            }
        }
    }
}
