body.podcast {
    .podcast-leaderboard {
        background: #1c1c1c;
        padding: 100px 0;
        position: relative;
        top: -3px;
        @media (max-width: 600px) {
            padding: 50px 0;
        }
        .leaderboard-container {
            width: 1060px;
            margin: 0 auto;
            color: $white;
            display: grid;
            grid-template-columns: 266px 1fr;
            gap: 55px;
            align-items: center;
            @media (max-width: 600px) {
                display: block;
                width: 100%;
            }
            h1 {
                margin: 0;
                font-size: 3rem;
                font-family: $playfair;
                font-weight: normal;
                @media (max-width: 600px) {
                    font-size: 2.3rem;
                    margin-top: 20px;
                }
            }
            p {
                margin-top: 10px;
                font-size: 1.125rem;
                font-family: $opensans;
                line-height: 2.1rem;
            }
            figure {
                margin: 0;
                padding: 0;
                img {
                    width: 100%;
                    height: auto;
                    border-radius: 5%;
                    object-fit: cover;
                }
            }
            .leaderboard-image {
                @media (max-width: 600px) {
                    padding: 0 20px;
                    width: 60%;
                    text-align: center;
                    margin: 0 auto;
                }
            }
            .leaderboard-text {
                position: relative;
                bottom: -25px;
                @media (max-width: 600px) {
                    padding: 0 20px;
                    bottom: 0;
                }
                figure {
                    text-align: right;
                    position: relative;
                    img {
                        width: 120px;
                        height: auto;
                    }
                }
            }
        }
    }
    .stream-links-box {
        background: #f8f8f8;
        padding: 55px 0;
        .stream-links-container {
            width: 1060px;
            margin: 0 auto;
            @media (max-width: 600px) {
                width: 100%;
                text-align: center;
            }
            p {
                font-family: $opensans;
                font-size: 1.125rem;
                margin: 0;
                padding: 0;
                line-height: 1.8rem;
                strong {
                    font-family: $opensans;
                    text-transform: uppercase;
                    font-size: 1.0625rem;
                    color: $black;
                    font-weight: 700;
                    letter-spacing: 1px;
                }
            }
            figure {
                margin: 0;
                margin-top: 20px;
                padding: 0;
                padding-right: 20px;
                display: inline-block;
                @media (max-width: 600px) {
                    padding-right: 0;
                    display: block;
                }
                img {
                    width: auto;
                    height: 64px;
                }
            }
        }
    }
    .podcast-content {
        width: 1060px;
        margin: 0 auto;
        @media (max-width: 600px) {
            width: 100%;
        }
        h2 {
            font-size: 2.375rem;
            font-family: $playfair;
            font-weight: 400;
            @media (max-width: 600px) {
                padding: 0 20px;
            }
        }
        .podcast-episode-container {
            padding: 80px 0;
            border-top: 2px solid #ebebeb;
            &:first-of-type {
                padding-top: 0;
                border-top: 0;
            }
            @media (max-width: 600px) {
                padding: 80px 20px;
            }
        }
        .podcast-episode {
            .podcast-episode-item {
                display: grid;
                grid-template-columns: 266px 1fr;
                gap: 30px;
                @media (max-width: 600px) {
                    display: block;
                }
                p {
                    font-size: 1.125rem;
                    font-family: $opensans;
                    line-height: 1.6rem;
                    margin-top: 0;
                    &.timestamp {
                        margin: 0;
                        font-family: $opensans;
                        font-size: 1rem;
                        line-height: auto;
                        @media (max-width: 600px) {
                            margin-top: 20px;
                        }
                    }
                    &.transcript {
                        font-weight: 700;
                        font-size: 1rem;
                        span {
                            padding-left: 10px;
                        }
                    }
                }
                audio {
                    width: 80%;
                    @media (max-width: 600px) {
                        width: 100%;
                    }
                }
                h3 {
                    font-family: $opensans;
                    margin: 9px 0;
                    font-size: 1.25rem;
                }
                .image {
                    @media (max-width: 600px) {
                        text-align: center;
                        margin: 0 auto;
                    }
                    img {
                        width: 100%;
                        height: auto;
                        border-radius: 5%;
                        object-fit: cover;
                        filter: drop-shadow(-1px 5px 3px #bfbfbf);
                        @media (max-width: 600px) {
                            width: 60%;
                        }
                    }
                }
            }
        }
        .podcast-related-articles {
            h4 {
                font-family: $playfair;
                font-size: 1.375rem;
                font-weight: 400;
            }
            .related-articles-container {
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                align-items: center;
                gap: 20px;
                @media (max-width: 600px) {
                    grid-template-columns: repeat(2, 1fr);
                }
                img {
                    width: 100%;
                    height: auto;
                }
                p {
                    margin-top: 0;
                    &.related-headline {
                        font-family: $opensans;
                        font-size: 0.9rem;
                        margin-bottom: 0;
                    }
                }
                .section {
                    text-transform: uppercase;
                    font-size: 0.8rem;
                    a {
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }
}
