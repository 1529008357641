.special-report-page {
    .caption {
        margin: 4px 0 0 10px;
        padding: 0;
        color: #353535;
        font-family: $opensans;
        font-size: 0.9rem;
        line-height: 1.125;
        a {
            text-decoration: underline;
            color: #353535;
            &:hover {
                text-decoration: none;
            }
        }
        .credit {
            margin: 0;
            padding: 0;
            color: #808080;
            font-family: $opensans;
            font-size: 0.75rem;
            line-height: 1.125;
            a {
                text-decoration: none !important;
                color: #7d7d7d;
                border-bottom: 2px solid #7d7d7d;
                &:hover {
                    text-decoration: none;
                    border-bottom: 0;
                }
            }
        }
    }
    aside {
        .section-heading-item {
            margin: 3rem 0 2rem 0;
        }
        hr.article-divider {
            border: 0;
            &:before {
                background: $black;
                height: 2px;
                width: 60%;
                content: '';
                display: inline-block;
                vertical-align: top;
            }
        }
    }
    .main-content {
        &.special-report {
            margin-top: 3rem;
        }
    }
}

.special-report-header {
    &.two-columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0;
        align-items: center;
        border-bottom: 1px solid #ccc;
        @media (max-width: 1000px) {
            grid-template-columns: 1fr;
        }
        img {
            width: 100%;
            height: auto;
            display: block;
            @media (max-width: 1000px) {
                max-width: 100vw;
            }
        }

        .special-report-text-row img {
            width: auto;
            max-width: 300px;
            max-height: 60px;
            margin: 5px auto;
            @media (max-width: 1000px) {
                max-width: 100vw;
            }
        }
    }
    .special-report-column {
        text-align: center;
        &.two-rows {
            display: grid;
            grid-template-rows: 1fr 67px;
            align-items: center;
            justify-items: center;
        }
        .special-report-text-row {
            width: 100%;
            &:nth-of-type(2) {
                text-transform: uppercase;
                padding: 24px 0;
                font-size: 0.75rem;
                font-family: 'Open Sans', sans-serif;
                letter-spacing: 2px;
                font-weight: 600;
            }
            .special-report-text {
                padding: 50px 100px 40px;
                @media (max-width: 600px) {
                    padding: 50px 20px 40px;
                }
                hr {
                    height: 3px;
                    background: $black;
                    border: 0;
                }
                .special-report-heading {
                    background: $black;
                    padding: 5px 14px;
                    color: #fff;
                    text-transform: uppercase;
                    font-family: $opensans;
                    font-weight: 700;
                    position: relative;
                    top: -20px;
                }
                h1 {
                    font-family: $playfair;
                    font-size: 2.75rem;
                    text-transform: uppercase;
                    letter-spacing: 3px;
                    margin: 0;
                    padding: 0;
                }
                h2 {
                    font-family: $opensans;
                    font-size: 1.1rem;
                    line-height: 1.7rem;
                    font-weight: 400;
                    margin: 0;
                    padding: 0;
                    padding-bottom: 5px;
                }
            }
        }
    }
}

.main-content {
    &.special-report {
        max-width: 1100px;
        margin: 0 auto;
        border-bottom: 0;
        .article-columns {
            display: grid;
            grid-template-columns: 1fr 300px;
            grid-gap: 46px;
            .articleLeft {
                .article-list {
                    border-bottom: 0;
                    .article-container {
                        border-bottom: 0;
                        .article-list-featured-item {
                            margin-top: 2rem;
                        }
                    }
                }
            }
            .article-container {
                .special-article-feature {
                    margin-bottom: 40px;
                    border-bottom: 1px solid #ccc;
                    padding: 30px 0;
                    .article-list-item {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        gap: 30px;
                        vertical-align: top;
                        margin-bottom: 30px;
                        &:first-of-type {
                            display: inline-block;
                        }
                        .article-list-image {
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
                        .article-list-text {
                            display: inline-block;
                            vertical-align: middle;
                            h3 {
                                font-family: $playfair;
                                font-weight: normal;
                                padding: 13px 0;
                                margin: 0;
                                font-size: 1.7rem;
                                line-height: 1.1;
                            }
                            .article-series {
                                padding: 0;
                                margin: 0 0 4px 0;
                                text-transform: uppercase;
                                font-weight: 600;
                                font-family: $opensans;
                                font-size: 0.95rem;
                                letter-spacing: 1px;
                            }
                            .article-section {
                                padding: 0;
                                margin: 0 0 4px 0;
                                text-transform: uppercase;
                                font-weight: 600;
                                font-family: $opensans;
                                font-size: 0.95rem;
                                letter-spacing: 1px;
                                .arts-culture {
                                    border-bottom: 3px solid $artsculture;
                                    &:hover {
                                        color: $artsculture;
                                        text-decoration: none;
                                    }
                                }
                                .history {
                                    border-bottom: 3px solid $history;
                                    &:hover {
                                        color: $history;
                                        text-decoration: none;
                                    }
                                }
                                .innovation {
                                    border-bottom: 3px solid $innovation;
                                    &:hover {
                                        color: $innovation;
                                        text-decoration: none;
                                    }
                                }
                                .science-nature {
                                    border-bottom: 3px solid $science;
                                    &:hover {
                                        color: $science;
                                        text-decoration: none;
                                    }
                                }
                                .smart-news {
                                    border-bottom: 3px solid $smartnews;
                                    &:hover {
                                        color: $smartnews;
                                        text-decoration: none;
                                    }
                                }
                                .smithsonian-institution {
                                    border-bottom: 3px solid $smithsonian;
                                    &:hover {
                                        color: $smithsonian;
                                        text-decoration: none;
                                    }
                                }
                                .travel {
                                    border-bottom: 3px solid $travel;
                                    &:hover {
                                        color: $travel;
                                        text-decoration: none;
                                    }
                                }
                            }
                            .article-dek {
                                font-family: $opensans;
                                padding: 0;
                                margin: 7px 0 0 0;
                                font-size: 0.95rem;
                            }
                            .article-date {
                                display: block;
                            }
                            .article-author {
                                font-family: $opensans;
                                font-weight: 700;
                                font-size: 0.9rem;
                                margin: 9px 0 0 0;
                                padding: 0;
                                a {
                                    border-bottom: 0;
                                    text-decoration: none;
                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                            }
                            .article-published {
                                font-family: $opensans;
                                font-size: 0.9rem;
                                margin: 9px 0 0 0;
                                padding: 0;
                                font-weight: 600;
                            }
                        }
                    }
                }
                .article-list-grid {
                    border-bottom: 1px solid #ccc;
                    border-top: 1px solid #ccc;
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 30px;
                    vertical-align: top;
                    margin-bottom: 30px;
                    h2 {
                        grid-column: 1 / -1;
                    }
                    .article-list-item {
                        display: inline-block;
                        padding: 0;
                        border-bottom: 0;
                        margin: 20px 0 40px 0;
                        &.sponsored-article {
                            padding: 15px;
                            border-bottom: 3px solid #000;
                        }
                        .article-list-image {
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
                        .article-list-text {
                            display: inline-block;
                            vertical-align: middle;
                            h3 {
                                font-family: $playfair;
                                font-weight: normal;
                                padding: 13px 0;
                                margin: 0;
                                font-size: 1.7rem;
                                line-height: 1.1;
                            }
                            .article-series {
                                padding: 0;
                                margin: 0 0 4px 0;
                                text-transform: uppercase;
                                font-weight: 600;
                                font-family: $opensans;
                                font-size: 0.95rem;
                                letter-spacing: 1px;
                            }
                            .article-section {
                                padding: 0;
                                margin: 0 0 4px 0;
                                text-transform: uppercase;
                                font-weight: 600;
                                font-family: $opensans;
                                font-size: 0.95rem;
                                letter-spacing: 1px;
                                .arts-culture {
                                    border-bottom: 3px solid $artsculture;
                                    &:hover {
                                        color: $artsculture;
                                        text-decoration: none;
                                    }
                                }
                                .history {
                                    border-bottom: 3px solid $history;
                                    &:hover {
                                        color: $history;
                                        text-decoration: none;
                                    }
                                }
                                .innovation {
                                    border-bottom: 3px solid $innovation;
                                    &:hover {
                                        color: $innovation;
                                        text-decoration: none;
                                    }
                                }
                                .science-nature {
                                    border-bottom: 3px solid $science;
                                    &:hover {
                                        color: $science;
                                        text-decoration: none;
                                    }
                                }
                                .smart-news {
                                    border-bottom: 3px solid $smartnews;
                                    &:hover {
                                        color: $smartnews;
                                        text-decoration: none;
                                    }
                                }
                                .smithsonian-institution {
                                    border-bottom: 3px solid $smithsonian;
                                    &:hover {
                                        color: $smithsonian;
                                        text-decoration: none;
                                    }
                                }
                                .travel {
                                    border-bottom: 3px solid $travel;
                                    &:hover {
                                        color: $travel;
                                        text-decoration: none;
                                    }
                                }
                            }
                            .article-dek {
                                font-family: $opensans;
                                padding: 0;
                                margin: 7px 0 0 0;
                                font-size: 0.95rem;
                            }
                            .article-date {
                                display: block;
                            }
                            .article-author {
                                text-transform: uppercase;
                                font-family: $opensans;
                                font-weight: 700;
                                font-size: 0.9rem;
                                margin: 9px 0 0 0;
                                padding: 0;
                                a {
                                    border-bottom: 0;
                                }
                            }
                            .article-published {
                                font-family: $opensans;
                                font-size: 0.9rem;
                                margin: 9px 0 0 0;
                                padding: 0;
                                font-weight: 600;
                            }
                        }
                    }
                }
                .article-list-special-list {
                    .article-list-special-header {
                        margin: 0;
                        padding: 0;
                    }
                    .article-list-item {
                        display: grid;
                        grid-template-columns: 250px 1fr;
                        grid-gap: 30px;
                        border-bottom: 1px solid #ccc;
                        padding: 30px 0;
                        &:first-of-type {
                            padding-top: 0 !important;
                        }
                        &:last-child {
                            border: 0;
                        }
                        .article-list-image {
                            img {
                                width: 100%;
                                height: auto;
                                border: 1px solid #e7e7e7;
                            }
                        }
                        .article-list-text {
                            display: inline-block;
                            vertical-align: middle;
                            h3 {
                                font-family: $playfair;
                                font-weight: normal;
                                padding: 0 0 13px 0;
                                margin: 0;
                                font-size: 1.7rem;
                                line-height: 1.1;
                            }
                            .article-series {
                                padding: 0;
                                margin: 0 0 17px 0;
                                text-transform: uppercase;
                                font-weight: 600;
                                font-family: $opensans;
                                font-size: 0.95rem;
                                letter-spacing: 1px;
                            }
                            .article-section {
                                padding: 0;
                                margin: 0 0 17px 0;
                                text-transform: uppercase;
                                font-weight: 600;
                                font-family: $opensans;
                                font-size: 0.95rem;
                                letter-spacing: 1px;
                                .arts-culture {
                                    border-bottom: 3px solid $artsculture;
                                    &:hover {
                                        color: $artsculture;
                                        text-decoration: none;
                                    }
                                }
                                .history {
                                    border-bottom: 3px solid $history;
                                    &:hover {
                                        color: $history;
                                        text-decoration: none;
                                    }
                                }
                                .innovation {
                                    border-bottom: 3px solid $innovation;
                                    &:hover {
                                        color: $innovation;
                                        text-decoration: none;
                                    }
                                }
                                .science-nature {
                                    border-bottom: 3px solid $science;
                                    &:hover {
                                        color: $science;
                                        text-decoration: none;
                                    }
                                }
                                .smart-news {
                                    border-bottom: 3px solid $smartnews;
                                    &:hover {
                                        color: $smartnews;
                                        text-decoration: none;
                                    }
                                }
                                .smithsonian-institution {
                                    border-bottom: 3px solid $smithsonian;
                                    &:hover {
                                        color: $smithsonian;
                                        text-decoration: none;
                                    }
                                }
                                .travel {
                                    border-bottom: 3px solid $travel;
                                    &:hover {
                                        color: $travel;
                                        text-decoration: none;
                                    }
                                }
                            }
                            .article-dek {
                                font-family: $opensans;
                                padding: 0;
                                margin: 7px 0 0 0;
                                font-size: 0.95rem;
                            }
                            .article-date {
                                display: block;
                            }
                            .article-author {
                                text-transform: uppercase;
                                font-family: $opensans;
                                font-weight: 700;
                                font-size: 0.9rem;
                                margin: 9px 0 0 0;
                                padding: 0;
                                a {
                                    border-bottom: 0;
                                }
                            }
                            .article-published {
                                font-family: $opensans;
                                font-size: 0.9rem;
                                margin: 9px 0 0 0;
                                padding: 0;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
            .article-teaser .headline {
                line-height: 1.2;
            }
        }
    }
}

.special-report-featured-stories {
    max-width: 1100px;
    margin: 2rem auto;
    padding: 0 20px;
    .article-list-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        vertical-align: top;
        @media (max-width: 800px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 600px) {
            grid-template-columns: 1fr;
        }
        .article-list-item {
            display: inline-block;
            padding: 0;
            border-bottom: 0;
            margin: 20px 0 40px 0;
            &.sponsored-article {
                padding: 15px;
                border-bottom: 3px solid #000;
            }
            .article-list-image {
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .article-list-text {
                display: inline-block;
                vertical-align: middle;
                h3 {
                    font-family: $playfair;
                    font-weight: normal;
                    padding: 13px 0;
                    margin: 0;
                    font-size: 1.7rem;
                    line-height: 1.1;
                }
                .article-series {
                    padding: 0;
                    margin: 0 0 4px 0;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-family: $opensans;
                    font-size: 0.95rem;
                    letter-spacing: 1px;
                }
                .article-section {
                    padding: 0;
                    margin: 4px 0;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-family: 'Open Sans', sans-serif;
                    font-size: 0.95rem;
                    letter-spacing: 1px;
                    line-height: 1.7;
                    .arts-culture {
                        border-bottom: 3px solid $artsculture;
                        &:hover {
                            color: $artsculture;
                            text-decoration: none;
                        }
                    }
                    .history {
                        border-bottom: 3px solid $history;
                        &:hover {
                            color: $history;
                            text-decoration: none;
                        }
                    }
                    .innovation {
                        border-bottom: 3px solid $innovation;
                        &:hover {
                            color: $innovation;
                            text-decoration: none;
                        }
                    }
                    .science-nature {
                        border-bottom: 3px solid $science;
                        &:hover {
                            color: $science;
                            text-decoration: none;
                        }
                    }
                    .smart-news {
                        border-bottom: 3px solid $smartnews;
                        &:hover {
                            color: $smartnews;
                            text-decoration: none;
                        }
                    }
                    .smithsonian-institution {
                        border-bottom: 3px solid $smithsonian;
                        &:hover {
                            color: $smithsonian;
                            text-decoration: none;
                        }
                    }
                    .travel {
                        border-bottom: 3px solid $travel;
                        &:hover {
                            color: $travel;
                            text-decoration: none;
                        }
                    }
                }
                .article-dek {
                    font-family: $opensans;
                    padding: 0;
                    margin: 7px 0 0 0;
                    font-size: 0.95rem;
                }
                .article-date {
                    display: block;
                }
                .article-author {
                    text-transform: uppercase;
                    font-family: $opensans;
                    font-weight: 700;
                    font-size: 0.9rem;
                    margin: 9px 0 0 0;
                    padding: 0;
                    a {
                        border-bottom: 0;
                    }
                }
                .article-published {
                    font-family: $opensans;
                    font-size: 0.9rem;
                    margin: 9px 0 0 0;
                    padding: 0;
                    font-weight: 600;
                }
            }
        }
    }
}

.special-report-article-listings {
    margin-bottom: 4rem;
}

.special-report-article-stream {
    h2 {
        margin-bottom: 2rem;
    }
}
