header.main {
    width: 100%;
    position: sticky;
    z-index: 9999;
    .headerBar {
        background: $black;
        margin: 0 auto;
        display: grid;
        padding: 10px 5px;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        @media (max-width: 850px) {
            grid-template-columns: 2fr 2fr 3fr;
        }
        @media (max-width: 650px) {
            grid-template-columns: repeat(3, 1fr);
        }
        .headerBaritem {
            &:last-of-type {
                text-align: right;
                margin-right: 10px;

                @media (max-width: 650px) {
                    .subscribe-btn,
                    .subscribe-btn-alternate {
                        display: none;
                        height: 25px;
                        a {
                            font-size: 0.7rem;
                            letter-spacing: 1px;
                            padding: 0;
                            margin: 0;
                        }
                    }
                    .gift-btn {
                        height: 25px;
                        a {
                            font-size: 0.7rem;
                            letter-spacing: 1px;
                            padding: 0;
                            margin: 0;
                        }
                    }
                    .renew-btn,
                    .renew-btn-alternate {
                        display: none;
                    }
                    .shop-btn {
                        display: none;
                    }
                }
            }
            &.logo {
                text-align: center;
            }
            .searchIcon {
                margin-left: 10px;
                display: inline-block;
                @media (max-width: 650px) {
                    display: none;
                }
                i {
                    color: $white;
                }
            }
            img {
                width: 135px;
                height: auto;
                text-align: center;
            }
            .shop-btn {
                width: 50px;
                margin-left: 20px;

                @media (max-width: 850px) {
                    margin-left: 0;
                }
            }
            // Hamburger animation
            button.hamburger {
                background-color: inherit;
                border: none;
                outline: none;
            }

            .hamMenu {
                width: 120px;
                padding: 7px 0;
                border: 2px solid #777;
                cursor: pointer;

                @media (max-width: 650px) {
                    width: auto;
                    border: none;
                }

                #mobile-icon {
                    width: 21px;
                    height: 13px;
                    margin: 0 10px;
                    position: relative;
                    transform: rotate(0deg);
                    transition:
                        all 0.5s ease-in-out,
                        top 0;
                    transition: top 0;
                    cursor: pointer;
                    p {
                        color: $white;
                        position: relative;
                        left: 32px;
                        margin: 0;
                        top: -3px;
                        font-weight: 700;
                        font-family: 'Open Sans', sans-serif;
                        font-size: 0.8rem;
                        letter-spacing: 1px;
                        text-transform: uppercase;

                        @media (max-width: 650px) {
                            display: none;
                        }
                    }
                    span {
                        display: block;
                        position: absolute;
                        height: 2px;
                        width: 100%;
                        background: #fff;
                        border-radius: 9px;
                        opacity: 1;
                        left: 0;
                        transform: rotate(0deg);
                        transition: 0.25s ease-in-out;
                    }
                    span:nth-child(1) {
                        top: 0px;
                    }
                    span:nth-child(2),
                    span:nth-child(3) {
                        top: 6px;
                    }
                    span:nth-child(4) {
                        top: 12px;
                    }
                    &.open span:nth-child(1) {
                        top: 18px;
                        width: 0%;
                        left: 50%;
                    }
                    &.open span:nth-child(2) {
                        transform: rotate(45deg);
                    }
                    &.open span:nth-child(3) {
                        transform: rotate(-45deg);
                    }
                    &.open span:nth-child(4) {
                        top: 18px;
                        width: 0%;
                        left: 50%;
                    }
                }
            }
        }
    }
    .navigation {
        width: 330px;
        height: 100%;
        margin-top: 62px;
        margin-right: -250px;
        padding-right: 250px;
        border-top: 1px solid $dark_grey;
        top: 0px;
        left: -200vw;
        bottom: 0px;
        transform: translate3d(0px, 0px, 0px);
        transition: left cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
        position: fixed;
        overflow-y: scroll;
        z-index: 6;
        // Hide scrollbar
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            width: 0;
            background: transparent;
        }
        @media (max-width: 600px) {
            width: 100vw;
            height: 100vh;
        }

        &.open {
            left: 0;
        }

        nav {
            padding-bottom: 600px;
            background: $black;
            box-sizing: border-box;
            outline: none;
            position: relative;
            z-index: 9;
            @media (max-width: 600px) {
                padding-bottom: 0;
            }
            .navWrap.search-wrapper {
                padding: 1rem 40px;
                border-top: 1px solid #333;
                border-bottom: 1px solid #333;
                position: relative;
                .search-form {
                    display: block;
                    input {
                        width: 100%;
                        padding: 10px 15px;
                        background-color: #2a2a2a;
                        border: none;
                        color: #ececec;
                        font-size: 0.9rem;
                        outline: none;
                        box-sizing: border-box;

                        &::placeholder {
                            color: #ececec;
                            opacity: 1;
                        }
                    }
                    button {
                        width: auto;
                        padding: 0;
                        background-color: #2a2a2a;
                        border: none;
                        color: #fff;
                        font-size: 0.9rem;
                        position: absolute;
                        top: 26px;
                        right: 47px;
                        cursor: pointer;
                    }
                }
            }
            .circulation {
                border-bottom: 1px solid $dark_grey;
                .subscrive-btn {
                    margin-right: 0;
                }
                .renew-btn {
                    margin-top: 20px;
                }
                .gift-btn {
                    margin-top: 20px;
                }
            }
            .navWrap {
                padding: 25px 40px;

                @media (max-width: 600px) {
                    max-height: 70vh;
                    overflow: scroll;
                }

                &.menu {
                    padding: 25px 0 25px 30px;
                }

                .subscribe-btn,
                .subscribe-btn-alternate {
                    width: 100%;
                    margin-bottom: 10px;
                }
                .renew-btn,
                .renew-btn-alternate {
                    width: 100%;
                }
                .gift-btn,
                .gift-btn-alternate {
                    width: 100%;
                    margin-bottom: 10px;
                }

                ul {
                    margin: 0;
                    padding: 0;
                    position: relative;
                    transition: height 0.2s;

                    li {
                        display: block;
                        padding: 15px;
                        position: relative;

                        @media (max-width: 600px) {
                            position: relative;
                            right: inherit;
                        }

                        a {
                            @media (max-width: 600px) {
                                border-bottom: 2px solid #fff;

                                &.smart-news {
                                    border-bottom: 2px solid #19b585;
                                }
                                &.history {
                                    border-bottom: 2px solid #059ad1;
                                }
                                &.science,
                                &.science-nature {
                                    border-bottom: 2px solid #afc736;
                                }
                                &.ingenuity {
                                    border-bottom: 2px solid #f8e208;
                                }
                                &.innovation {
                                    border-bottom: 2px solid #f8e208;
                                }
                                &.arts-culture {
                                    border-bottom: 2px solid #a24b9d;
                                }
                                &.travel {
                                    border-bottom: 2px solid #f26328;
                                }
                                &.smithsonian,
                                &.at-the-smithsonian,
                                &.smithsonian-institution {
                                    border-bottom: 2px solid #1cbdc4;
                                }
                            }
                        }

                        ul {
                            position: absolute;
                            right: 100vw;

                            @media (max-width: 600px) {
                                padding: 5px 0;
                                display: grid;
                                grid-template-columns: repeat(2, 1fr);
                                gap: 0;
                                position: relative;
                                right: inherit;
                            }

                            li {
                                padding: 8px 15px;
                                color: $black;
                                font-family: 'Open Sans', sans-serif;
                                text-transform: uppercase;
                                font-size: 0.8rem;
                                font-weight: 700;
                                line-height: 24px;
                                letter-spacing: 1px;

                                @media (max-width: 600px) {
                                    padding: 10px 0;
                                    border-bottom: none;
                                    line-height: 1.2;
                                }

                                &.heading {
                                    height: auto;
                                    margin: auto;
                                    border-top: none;
                                    border-left: none;
                                    text-decoration: none;
                                    @media (max-width: 600px) {
                                        margin: 0;
                                    }
                                    &:hover {
                                        @media (max-width: 600px) {
                                            background: #000;
                                        }
                                    }
                                }

                                &:hover {
                                    background: #f6f6f6;
                                    @media (max-width: 600px) {
                                        background: #000;
                                    }
                                }

                                a {
                                    border-bottom: none;
                                    font-family: $opensans;
                                    text-transform: none;
                                    font-size: 0.8rem;
                                    color: $white;
                                    font-weight: 700;
                                    letter-spacing: 1px;

                                    @media (max-width: 600px) {
                                        font-weight: 400;
                                    }

                                    &:hover {
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                        &:after {
                            position: absolute;
                            content: '';
                            display: inline-block;
                            width: 0.35em;
                            height: 0.35em;
                            border-right: 0.15em solid $white;
                            border-top: 0.15em solid $white;
                            transform: rotate(45deg);
                            margin-right: 0.5em;
                            right: 33px;
                            top: 20px;

                            @media (max-width: 600px) {
                                display: none;
                            }
                        }

                        @media (min-width: 601px) {
                            &:hover {
                                background: #2a2a2a;
                                .arts-culture {
                                    border-bottom: 3px solid $artsculture;
                                }
                                .history {
                                    border-bottom: 3px solid $history;
                                }
                                .innovation {
                                    border-bottom: 3px solid $innovation;
                                }
                                .ingenuity {
                                    border-bottom: 3px solid #f8e208;
                                }
                                .science,
                                .science-nature {
                                    border-bottom: 3px solid $science;
                                }
                                .smart-news {
                                    border-bottom: 3px solid $smartnews;
                                }
                                .smithsonian-institution,
                                .at-the-smithsonian,
                                .smithsonian {
                                    border-bottom: 3px solid $smithsonian;
                                }
                                .travel {
                                    border-bottom: 3px solid $travel;
                                }
                                .photos,
                                .video,
                                .games {
                                    border-bottom: 3px solid #fff;
                                }
                                &:after {
                                    display: none;
                                }
                                ul {
                                    position: absolute;
                                    background: $white;
                                    left: 300px;
                                    width: 200px;
                                    padding: 10px 20px;
                                    top: 0;
                                    box-shadow: 0px 0px 5px 0px #ccc;
                                    li {
                                        a {
                                            color: $black;
                                            font-family: 'Open Sans', sans-serif;
                                            font-size: 0.9rem;
                                            font-weight: 400;
                                            line-height: 24px;
                                        }
                                    }
                                    &:before {
                                        content: '';
                                        width: 0;
                                        height: 0;
                                        border-top: 23px solid transparent;
                                        border-right: 30px solid $white;
                                        border-bottom: 25px solid transparent;
                                        position: absolute;
                                        left: -30px;
                                        top: 0;
                                    }
                                }
                            }
                        }

                        a {
                            font-family: $opensans;
                            text-transform: uppercase;
                            font-size: 0.8rem;
                            color: $white;
                            font-weight: 700;
                            letter-spacing: 1px;
                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
            .secondary {
                border-top: 1px solid $dark_grey;

                @media (max-width: 600px) {
                    background-color: #000;
                    position: fixed;
                    left: 0;
                    right: 0;
                    bottom: 60px;
                    display: none;
                }

                .navWrap ul li {
                    &:after {
                        display: none;
                    }
                    a {
                        border-bottom: none;
                    }
                }
            }

            a {
                color: $white;
            }

            .fa-xmark {
                color: #ccc;
                font-size: 20px;
                position: absolute;
                top: 2px;
                right: 3px;
                cursor: pointer;

                @media (max-width: 600px) {
                    top: 4px;
                    right: 19px;
                }
            }
        }
    }
}

body.home header.main .headerBar .headerBaritem.logo {
    opacity: 0;
}
