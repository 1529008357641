.main-content {
    &.article {
        font-family: $opensans;
        font-size: 1.1rem;
        line-height: 1.7;
        header {
            padding-top: 20px;
            @media (max-width: 600px) {
                text-align: center;
            }
            .series-tag p {
                margin: 5px 0 10px;
                font-size: 1.063rem;
                font-weight: 700;
                a {
                    text-decoration: none;
                }
            }
        }
        h1 {
            font-family: $playfair;
            font-size: 2.813rem;
            font-weight: 400;
            line-height: 2.813rem;
            margin: 0;
            padding: 0;

            @media (max-width: 600px) {
                font-size: 2rem;
                line-height: 1;
            }
        }
        h2 {
            font-family: $playfair;
            font-size: 2.375rem;
            font-weight: 400;
            line-height: 3.75rem;
            margin: 0;
            padding: 0;
        }
        h3 {
            font-family: $playfair;
            font-size: 1.625rem;
            font-weight: 700;
            line-height: 2rem;
            margin: 0;
            padding: 0;
        }
        h4 {
        }
        h5 {
        }
        h6 {
        }
        ol,
        ul {
            font-family: $opensans;
            font-size: 1.1rem;
            line-height: 1.7;
            @media (max-width: 600px) {
                margin-left: 35px;
            }
            a {
                text-decoration: underline;
                text-underline-offset: 2px;
                text-decoration-thickness: 2px;
                &:hover {
                    text-decoration: underline;
                    text-decoration-thickness: 2px;
                }
            }
        }
        ul {
            list-style-type: square;
        }
        hr {
            width: 186px;
            height: 3px;
            border: 0;
            text-align: center;
            margin: 15px auto 30px;
            background: #000;
            padding: 0;
        }
        &.blog {
            .section {
                a {
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            hr {
                margin: 15px 0 30px;
            }
        }
        p.subtitle {
            font-family: $opensans;
            font-size: 1.4rem;
            line-height: 1.3;
            font-weight: 400;
            margin: 21px 0 17px 0;
            padding: 0;

            @media (max-width: 600px) {
                font-size: 1.1rem;
            }
        }
        p {
            font-family: $opensans;
            font-size: 1.1rem;
            line-height: 1.88;
            word-break: break-word;
            a {
                text-decoration: underline;
                text-underline-offset: 2px;
                text-decoration-thickness: 2px;

                &:hover {
                    color: #808080;
                    text-decoration: underline;
                    text-underline-offset: 2px;
                    text-decoration-thickness: 2px;
                }
            }
        }
        img {
            width: 100%;
            height: auto;
            &.left {
                float: left;
                width: 50%;
                height: auto;
            }
            &.right {
                float: right;
                width: 50%;
                height: auto;
            }
        }
        .cd-resize-img img {
            width: auto;
            height: 100%;
        }
        blockquote {
            border-left: 6px solid $black;
            margin: 40px 0;
            padding: 10px 0 10px 50px;
            font-style: italic;
            font-weight: 600;
            line-height: 1.5;
            font-family: $opensans;
            font-size: 1rem;
            p {
                margin: 0;
                padding: 0;
                font-weight: 600;
                line-height: 1.5;
                font-size: 1rem;
            }
        }
        figure {
            margin: 0;
            &.article-image {
                padding: 20px 0;
                &.lead-article-image {
                    padding-bottom: 20px;
                    padding-top: 0;
                }
                &.left {
                    float: left;
                    width: 307px;
                    margin-right: 44px;
                    margin-top: 0;
                    padding-top: 7px;
                    @media (max-width: 600px) {
                        float: none;
                        width: 100%;
                        margin-right: 0;
                    }
                }
                &.right {
                    float: right;
                    width: 307px;
                    margin-left: 44px;
                    margin-top: 0;
                    padding-top: 7px;
                    @media (max-width: 600px) {
                        float: none;
                        width: 100%;
                        margin-left: 0;
                    }
                }
            }
            &.article-video {
                padding: 20px 0;
                .credit {
                    margin-top: 4px;
                }
            }
            &.video {
                padding: 20px 0;
                .credit {
                    margin-top: 4px;
                }
                .embedly-plugin {
                    overflow: hidden;
                    .video-image {
                        transition: opacity 0.5s;
                        &:after {
                            content: '';
                            width: 75px;
                            height: 60px;
                            line-height: 0.5;
                            margin: auto;
                            font-size: 50px;
                            font-weight: 400;
                            text-align: center;
                            position: absolute;
                            top: 50%;
                            bottom: 50%;
                            left: 0;
                            right: 0;
                            z-index: 3;
                            pointer-events: none;
                            background: url('https://tf-cmsv2-smithsonianmag-media.s3.amazonaws.com/filer_public/51/fa/51faae38-52d7-4911-b53b-d2a907487bc7/youtube_play_button_icon.svg')
                                top left no-repeat;
                            @media (max-width: 600px) {
                                width: 50px;
                                height: 50px;
                            }
                        }
                        .youtube-video-title {
                            color: $white;
                            line-height: 1.5;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 3;
                            width: 100%;
                            padding: 15px 15px 25px 15px;
                            background: rgb(0, 0, 0);
                            background: linear-gradient(
                                180deg,
                                rgba(0, 0, 0, 1) 0%,
                                rgba(255, 255, 255, 0) 100%
                            );
                            box-sizing: border-box;
                            @media (max-width: 600px) {
                                font-size: 14px;
                            }
                        }
                        .watch-on-youtube {
                            padding: 10px 20px;
                            background-color: rgba(0, 0, 0, 0.7);
                            line-height: 1.5;
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            z-index: 3;
                            img {
                                margin: 0;
                                padding: 0;
                                position: relative;
                                top: 2px;
                                width: 70px;
                                height: auto;
                            }
                            a {
                                color: white;
                            }
                            @media (max-width: 600px) {
                                padding: 0 10px;
                                a {
                                    font-size: 15px;
                                }
                            }
                        }
                        img {
                            min-height: 100%;
                            max-height: 100%;
                            position: absolute;
                            cursor: pointer;
                            z-index: 2;
                            @media (max-width: 1100px) {
                                max-height: 35.7vw;
                            }
                            @media (max-width: 1000px) {
                                max-height: 53vw;
                            }
                        }
                    }
                    iframe {
                        z-index: 1;
                    }
                }
            }
            &.pullquote {
                font-family: $playfair;
                font-size: 2.2rem;
                line-height: 1;
                padding: 20px 0;
                &:after {
                    content: '';
                    display: block;
                    vertical-align: top;
                    background: $black;
                    width: 183px;
                    height: 3px;
                }
                p {
                    font-family: $playfair;
                    font-size: 2.2rem;
                    line-height: 1;
                    margin-top: 0;
                    margin-bottom: 23px;
                }
                &.left {
                    float: left;
                    width: 307px;
                    margin-right: 44px;
                    margin-top: 0;
                    padding-top: 7px;
                }
                &.right {
                    float: right;
                    width: 307px;
                    margin-left: 44px;
                    margin-top: 0;
                    padding-top: 7px;
                }
            }
            .caption {
                margin: 4px 0 0 0;
                padding: 0;
                color: #353535;
                font-family: $opensans;
                font-size: 0.9rem;
                line-height: 1.25;
                a {
                    text-decoration: underline;
                    color: #353535;
                    &:hover {
                        text-decoration: none;
                    }
                }
                .credit {
                    margin: 0;
                    padding: 0;
                    color: #808080;
                    font-family: $opensans;
                    font-size: 0.75rem;
                    line-height: 1.125;
                    a {
                        text-decoration: none !important;
                        color: #7d7d7d;
                        border-bottom: 2px solid #7d7d7d;
                        &:hover {
                            text-decoration: none;
                            border-bottom: 0;
                        }
                    }
                }
            }
        }
        .category-label {
            margin: 0;
            margin-top: 2.5rem;
            text-align: left;

            h2 {
                margin: 0;
                padding: 0.1rem 1.5rem 0.2rem 0.9rem;
                font-family: $opensans;
                font-size: 1.2rem;
                font-weight: bold;
                line-height: 1.1;

                @media (max-width: 600px) {
                    font-size: 1rem;

                    &:after {
                        top: 8px;
                    }
                }
            }
        }
        .binding-box {
            margin: 30px 0;
        }
        #desktopInArticle-0 {
            display: block;
            margin: 0 auto;
            text-align: center;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            padding: 2rem 0;
            min-height: 300px;
        }
        aside {
            .heading {
                height: auto;
                margin: 50px 0;
                padding: 0 20px;
                font-family: $opensans;
                font-size: 1.2rem;
                font-weight: 700;
            }
            .binding-box {
                margin: 0;
            }
        }
        .article-line {
            hr {
                background: $black;
                width: 186px;
                height: 3px;
                border: 0;
                text-align: left;
                margin: 0 0 40px 0;

                @media (max-width: 600px) {
                    margin: 20px auto 40px;
                }
            }
        }
        .article-columns {
            display: grid;
            grid-template-columns: minmax(0, 1fr) 300px;
            grid-gap: 46px;
            @media (max-width: 1000px) {
                display: block;
            }
            /* magazine promo box */
            .embedly-retail,
            .amazon-associated-product {
                display: grid;
                grid-template-columns: 150px 1fr;
                grid-gap: 25px;
                padding: 0 20px 20px;
                margin-top: -15px;
                @media (max-width: 600px) {
                    display: block;
                    text-align: center;
                }
                img {
                    width: 150px;
                    height: auto;
                }
                h3 {
                    font-family: $playfair;
                    font-weight: 300;
                    font-size: 1.7rem;
                    line-height: 1;
                    margin: 0;
                }
                p {
                    font-family: $opensans;
                    font-size: 0.95rem;
                    line-height: 1.3;
                }
                button {
                    background: $black;
                    border: 0;
                    color: $white;
                    text-transform: uppercase;
                    padding: 14px 65px;
                    font-weight: 600;
                    &:hover {
                        background: $pink;
                    }
                }
            }
        }
        .author-box {
            display: block;
            clear: both;
            &.by-line {
                width: 100%;
                .author-text {
                    padding-bottom: 25px;
                    p {
                        margin: 0;
                        padding: 0;
                        &.author {
                            margin-bottom: 12px;
                            font-weight: 700;
                            font-size: 0.95rem;
                            a {
                                border-bottom: 0;
                                text-decoration: none;
                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                        &.photographer {
                            font-weight: 700;
                            font-size: 0.95rem;
                            a {
                                border-bottom: 0;
                                text-decoration: none;
                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                        &.author-short-bio {
                            font-size: 0.95rem;
                            line-height: 1;
                            padding-bottom: 4px;
                        }
                    }
                    time {
                        font-size: 0.95rem;
                        font-weight: 600;
                    }
                }
                &.single-author {
                    display: grid;
                    grid-template-columns: 66px 1fr;
                    grid-gap: 22px;
                    @media (max-width: 600px) {
                        display: block;

                        hr {
                            margin: 0 auto 40px;
                        }
                    }
                }
                &.no-photo {
                    display: block !important;
                }
                &.footer-author-box {
                    display: grid;
                    grid-template-columns: 110px 1fr !important;
                    grid-gap: 22px;
                    margin: 2rem 0 2.5rem;
                    @media (max-width: 600px) {
                        text-align: center;
                        display: block;
                    }
                    &.author-list-page {
                        border-bottom: 3px solid #000;
                        align-items: center;
                        padding-bottom: 1.25rem;
                        .author-text {
                            padding-bottom: 0;
                        }
                        h1 {
                            display: inline-block;
                            border-bottom: 0;
                            margin: 0;
                            padding: 0;
                            line-height: 0;
                            @media (max-width: 600px) {
                                margin-top: 1rem;
                            }
                        }
                        .author-bio-text {
                            border-bottom: 0;
                            margin-bottom: 0;
                            padding-bottom: 0;
                        }
                    }
                    .author-headshot {
                        img {
                            width: 110px;
                            height: 110px;
                            background: $black;
                            object-fit: cover;
                            border-radius: 50%;
                        }
                        &.arts-culture {
                            img {
                                background: $artsculture;
                            }
                        }
                        &.history {
                            img {
                                background: $history;
                            }
                        }
                        &.innovation {
                            img {
                                background: $innovation;
                            }
                        }
                        &.science,
                        &.science-nature {
                            img {
                                background: $science;
                            }
                        }
                        &.smart-news {
                            img {
                                background: $smartnews;
                            }
                        }
                        &.smithsonian-institution,
                        &.at-the-smithsonian {
                            img {
                                background: $smithsonian;
                            }
                        }
                        &.travel {
                            img {
                                background: $travel;
                            }
                        }
                    }
                    .author-smaller {
                        font-size: 0.85rem;
                        text-transform: none;
                        .fab {
                            background: $black;
                            color: $white;
                            object-fit: cover;
                            border-radius: 50%;
                            font-size: 0.7rem;
                            padding: 5px;
                        }
                    }
                    .author-bio-text {
                        font-size: 0.95rem;
                        line-height: 1.5;
                        border-bottom: 3px solid #000;
                        padding-bottom: 15px;
                        a {
                            text-decoration: none;
                            border-bottom: 0;
                        }
                    }
                    .author-text p.author {
                        @media (max-width: 600px) {
                            .author-smaller,
                            .author-name {
                                display: block;
                            }

                            .separator {
                                display: none;
                            }
                        }
                    }
                }
            }
            .author-headshot {
                img {
                    width: 66px;
                    height: 66px;
                    background: $black;
                    object-fit: cover;
                    border-radius: 50%;
                }
                &.arts-culture {
                    img {
                        background: $artsculture;
                    }
                }
                &.history {
                    img {
                        background: $history;
                    }
                }
                &.innovation {
                    img {
                        background: $innovation;
                    }
                }
                &.science,
                &.science-nature {
                    img {
                        background: $science;
                    }
                }
                &.smart-news {
                    img {
                        background: $smartnews;
                    }
                }
                &.smithsonian-institution,
                &.at-the-smithsonian {
                    img {
                        background: $smithsonian;
                    }
                }
                &.travel {
                    img {
                        background: $travel;
                    }
                }
            }
        }
        .btn-comments {
            margin: 25px 0 0;
            background: none;
            border: none;
            text-align: center;
            display: block;
            font-size: 1rem;
            font-weight: 700;
            text-transform: uppercase;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
                text-decoration-thickness: 2px;
            }
            a {
                color: $white;
                padding: 5px;
                border: 0;
                font-family: $playfair;
                font-size: 1.5rem;
                font-weight: 700;
                text-transform: uppercase;
                text-align: center;
                margin: 0 auto;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        // color o
        .tag-list {
            font-weight: 600;
            font-size: 0.85rem;
            nav {
                a {
                    font-weight: 400;
                    font-size: 0.85rem;
                }
            }
        }
    }
    &.article-list {
        max-width: 1100px;
        width: auto;
        margin: 0 auto;

        @media (max-width: 1200px) {
            width: auto;
        }

        &.tag-list {
            h1 {
                border-bottom: 3px solid $black;
                font-family: $playfair;
                font-size: 2.75rem;
                font-weight: 300;
                line-height: 1.5;
                margin-bottom: 20px !important;
            }
        }
        &.category-list {
            h1 {
                border-bottom: 3px solid $black;
                font-family: $playfair;
                font-size: 2.75rem;
                font-weight: 300;
                line-height: 1.5;
                margin-bottom: 0;
                @media (max-width: 600px) {
                    padding: 5px 0 10px;
                    text-align: center;
                    line-height: 1;
                }
            }
            nav {
                border-bottom: 1px solid #808080;
                margin: 0 0 30px 0;
                padding: 18px 0;
                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                    li {
                        display: inline-block;
                        padding: 0;
                        margin: 0;
                        font-family: $opensans;
                        text-transform: uppercase;
                        font-weight: 700;
                        font-size: 0.95rem;
                        padding: 0 20px;
                        &:first-of-type {
                            padding-left: 0;
                        }
                        &:last-of-type {
                            padding-right: 0;
                        }
                        a {
                            border-bottom: 0;
                        }
                    }
                }
                &.history ul li a:hover {
                    text-decoration-color: #059ad1;
                }
                &.smart-news ul li a:hover {
                    text-decoration-color: #19b585;
                }
                &.science ul li a:hover {
                    text-decoration-color: #afc736;
                }
                &.ingenuity ul li a:hover {
                    text-decoration-color: #f8e208;
                }
                &.innovation ul li a:hover {
                    text-decoration-color: #f8e208;
                }
                &.arts-culture ul li a:hover {
                    text-decoration-color: #a24b9d;
                }
                &.travel ul li a:hover {
                    text-decoration-color: #f26328;
                }
                &.smithsonian ul li a:hover {
                    text-decoration-color: #1cbdc4;
                }
            }
        }
        .article-columns {
            display: grid;
            grid-template-columns: 1fr 300px;
            grid-gap: 46px;

            @media (max-width: 800px) {
                display: block !important;
            }

            .load-btn {
                background: $black;
                display: block;
                color: $white;
                margin: 0 auto;
                text-align: center;
                padding: 5px;
                border: 0;
                &:hover {
                    background: #666;
                }
                a {
                    color: $white;
                    padding: 5px;
                    border: 0;
                    font-family: $playfair;
                    font-size: 1.5rem;
                    font-weight: 700;
                    text-transform: uppercase;
                    text-align: center;
                    margin: 0 auto;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
            #loadMore {
                visibility: hidden;
            }
            .article-container {
                margin-bottom: 30px;
                border-bottom: 1px solid #ccc;
                .article-list-item {
                    display: grid;
                    grid-template-columns: 250px 1fr;
                    grid-gap: 2rem;
                    border-bottom: 1px solid #ccc;
                    padding: 2rem 0;
                    align-items: center;
                    &.larger {
                        display: block;
                        .article-list-image {
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
                        .article-list-text {
                            h3 {
                                font-size: 2.5rem;
                                line-height: 2.5rem;
                            }
                        }
                    }
                    &:last-child {
                        border: 0;
                    }
                    &:first-child {
                        padding-top: 0;
                    }

                    @media (max-width: 600px) {
                        text-align: center;
                        display: block !important;
                    }

                    .article-list-image {
                        img {
                            width: 250px;
                            height: auto;
                        }
                    }
                    .article-list-text {
                        display: inline-block;
                        vertical-align: middle;
                        h3 {
                            font-family: $playfair;
                            font-weight: normal;
                            padding: 0 0 13px 0;
                            margin: 0;
                            font-size: 1.7rem;
                            line-height: 1.1;
                            a:hover {
                                text-decoration: none;
                            }
                        }
                        .article-series {
                            padding: 0 0 13px 0;
                            margin: 0 0 4px 0;
                            text-transform: uppercase;
                            font-weight: 600;
                            font-family: $opensans;
                            font-size: 0.95rem;
                            letter-spacing: 1px;
                        }
                        .article-dek {
                            font-family: $opensans;
                            padding: 0;
                            margin: 7px 0 0 0;
                            font-size: 0.95rem;
                        }
                        .article-date {
                            display: block;
                        }
                        .article-author {
                            font-family: $opensans;
                            font-weight: 700;
                            font-size: 0.9rem;
                            margin: 9px 0 0 0;
                            padding: 0;
                            a {
                                border-bottom: 0;
                                text-decoration: none;
                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                        .article-published {
                            font-family: $opensans;
                            font-size: 0.9rem;
                            margin: 9px 0 0 0;
                            padding: 0;
                            font-weight: 600;
                        }
                    }
                }
                .article-list-featured-item {
                    padding-bottom: 2rem;
                    border-bottom: 1px solid #ccc;
                    img {
                        width: 100%;
                        height: auto;
                    }
                    h3 {
                        font-family: $playfair;
                        font-weight: normal;
                        padding: 0 0 13px 0;
                        margin: 0;
                        font-size: 1.7rem;
                        line-height: 1.1;
                    }
                    .article-dek {
                        font-family: $opensans;
                        padding: 0;
                        margin: 7px 0 0 0;
                        font-size: 0.95rem;
                    }
                }
            }
        }
        .loading-icon {
            width: 200px;
            margin: auto;
            display: block;
        }
    }
    &.blog {
        width: auto;
        max-width: 1000px;
    }
}

.articleRight {
    &.special-articleRight {
        .heading {
            margin-top: 2rem;
        }
        .article-list-featured-item {
            margin-top: 2rem;
            .article-list-image {
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .article-list-text {
                h3 {
                    font-weight: 400;
                    line-height: 1.35rem;
                    line-height: 1.5rem;
                }
                p {
                    font-family: $opensans;
                    font-size: 0.9rem;
                    line-height: 1.35rem;
                    font-weight: 400;
                }
            }
        }
        .article-list-item {
            margin-bottom: 1.5rem;
            &.list-no-images {
                margin-bottom: 0;
                .section {
                    display: none;
                }
                h3 {
                    font-weight: 400;
                    line-height: 1.35rem;
                    margin: 4px 0 0 0;
                    padding: 0;
                    &:last-of-type {
                        margin: 4px 0 0 0;
                    }
                }
            }
            .article-list-containment {
                &.two-column-items {
                    display: grid;
                    grid-template-columns: 100px 1fr;
                    grid-gap: 15px;
                    align-items: center;
                    .article-list-image {
                        img {
                            display: block;
                        }
                    }
                    .article-list-text {
                        h3 {
                            font-size: 1.17rem;
                            font-weight: 400;
                            line-height: 1.5rem;
                        }
                    }
                }
            }
            .article-list-containment {
                &.two-column-items {
                    display: grid;
                    grid-template-columns: 100px 1fr;
                    grid-gap: 15px;
                    align-items: center;
                    .article-list-image {
                        img {
                            display: block;
                        }
                    }
                    .article-list-text {
                        h3 {
                            font-size: 1.17rem;
                            font-weight: 400;
                            line-height: 1.5rem;
                        }
                    }
                }
            }
            .article-list-text {
                .article-published {
                    display: none;
                }
            }
        }
    }
}

.article-special-wrap {
    background: $black;
    color: $white;
    &.blogging {
        background: $white;
        color: $black;
        border-bottom: 1px solid #d9d9d9;
        &.blogging-single {
            text-align: center;
        }
        .article-special {
            align-items: center;
            h1,
            p {
                &.hed {
                    font-weight: 400;
                    letter-spacing: 0;
                    a {
                        text-decoration: none;
                        color: $black;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
            .article-special-logo {
                img {
                    width: 100% !important;
                    max-width: 100% !important;
                    max-height: 75px !important;
                }
            }
        }
    }
    .article-special {
        margin: 0 auto;
        width: 1000px;
        display: grid;
        grid-template-columns: 1fr 150px;
        padding: 15px 0;
        .article-special-text:last-child:nth-last-child(odd) {
            grid-column: auto / span 2;
        }
        p {
            margin: 0;
            padding: 0;
            line-height: 1.2;
            &.hed {
                font-family: $playfair;
                font-weight: 800;
                text-transform: uppercase;
                font-size: 1.9rem;
                margin: 0;
                letter-spacing: 2px;
            }
            &.dek {
                font-family: $opensans;
                padding-bottom: 5px;
                font-size: 0.9rem;
            }
        }
        a {
            color: $white;
        }
        .article-special-logo {
            justify-self: right;
            p {
                color: #d3d3d3;
                text-transform: uppercase;
                font-family: $opensans;
                font-size: 0.7rem;
                text-align: right;
                margin-bottom: 10px;
            }
            img {
                max-width: 300px;
                max-height: 60px;
                @media (max-width: 350px) {
                    max-width: 100%;
                }
            }
        }
    }
}

.recommended-videos {
    margin-bottom: 20px;
}

.affiliateLink {
    background: #f5f5f5;
    padding: 15px;
    p {
        padding: 0;
        margin: 0;
        line-height: 22px !important;
        font-size: 0.95rem !important;
        strong {
            text-transform: uppercase;
        }
    }
}
