.home {
    header.main .navigation {
        @media (max-width: 600px) {
            border-top: none;
            top: 10px;
        }
    }

    .advertisement.fullwidth.adtop {
        // margin-top: 20px;
    }

    .largemasthead {
        width: 1300px;
        max-width: 100%;
        margin: 0 auto;
        text-align: center;

        img.site-logo {
            width: 230px;
            margin: 1.3rem auto 1.3rem;
            @media (max-width: 600px) {
                display: none;
            }
        }

        .large-menu {
            padding: 0.7rem 0;
            border-top: 4px solid #000;

            ul li a:hover {
                text-decoration-thickness: 2px;
            }

            @media (max-width: 1350px) {
                padding-left: 20px;
                padding-right: 20px;
            }

            @media (max-width: 1000px) {
                display: none;
            }

            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                display: flex;
                justify-content: space-between;
                li {
                    margin: 0;
                    padding: 0;
                    font-family: $opensans;
                    text-transform: uppercase;
                    font-size: 0.8rem;
                    font-weight: 700;
                    letter-spacing: 1px;
                }
            }
        }
    }
    .main-content {
        width: 1300px;
        max-width: 100%;
        @media (max-width: 1400px) {
            width: auto;
        }
        @media (max-width: 400px) {
            overflow-x: hidden;
        }
        .home-featured-stories {
            display: grid;
            grid-gap: 30px;
            grid-template-columns: 1fr 300px;
            @media (max-width: 1000px) {
                grid-template-columns: 1fr;
            }
            .special-article-feature {
                margin-top: 45px;
                list-style-type: none;
                display: grid;
                grid-template-columns: 300px 1fr;
                grid-gap: 30px;
                @media (max-width: 800px) {
                    display: block;
                }
                @media (max-width: 600px) {
                    margin: 10px auto;
                }
                .article-list-item {
                    @media (max-width: 600px) {
                        margin-bottom: 50px;
                    }
                    &:first-child {
                        grid-column: 2;
                        grid-row: 1 / last-line;
                        grid-row-start: span 3;
                        position: relative;
                        z-index: 3;
                        text-align: center;
                        h3 {
                            font-family: $playfair;
                            font-size: 2.25rem;
                            font-weight: 300;
                            margin: 0;
                            line-height: 2.5rem;
                            @media (max-width: 600px) {
                                font-size: 2rem;
                                line-height: 1;
                            }
                        }
                        p.section {
                            margin: 1.5rem 0 1rem;
                        }
                        p.article-dek {
                            font-family: $opensans;
                            font-size: 1.2rem;
                            @media (max-width: 600px) {
                                font-size: 1.1rem;
                            }
                        }
                        p.author {
                            font-family: $opensans;
                            line-height: 1.7;
                            font-weight: 700;
                            font-size: 0.95rem;
                        }
                    }
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
        .latest-features-wrapper {
            margin-bottom: 2.2rem;
            .heading {
                height: auto;
                padding: 15px 15px 0;
            }
        }
        .latest-features {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 30px;
            margin-top: 30px;
            @media (max-width: 1000px) {
                grid-template-columns: repeat(3, 1fr);
            }
            @media (max-width: 800px) {
                grid-template-columns: repeat(2, 1fr);
            }
            @media (max-width: 600px) {
                grid-template-columns: 1fr;
            }
            .home-article-list-item {
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
        .smart-news {
            .category-label {
                margin-bottom: 0;
                @media (max-width: 600px) {
                    margin-top: 0;
                }
            }
            .home-smart-news {
                .article-list {
                    border: 0;
                }
                h3 {
                    font-weight: 400;
                    line-height: 1.35rem;
                    margin: 10px 0 0 0;
                    padding: 0;
                    &:last-of-type {
                        margin: 4px 0 10px 0;
                    }
                }
                p {
                    margin-top: 4px;
                    font-family: $opensans;
                    font-size: 0.85rem;
                    font-weight: 600;
                    &.section {
                        &.larger {
                            margin-top: 24px;
                            margin-bottom: 0;
                            padding-bottom: 0;
                        }
                    }
                }
                hr {
                    border: 0;
                    &:before {
                        background: $black;
                        height: 2px;
                        width: 60%;
                        content: '';
                        display: inline-block;
                        vertical-align: top;
                        margin-top: 7px;
                        @media (max-width: 600px) {
                            width: 100%;
                        }
                    }
                }
            }
        }
        .homepage-featured-categories {
            .home-category-stories {
                margin-top: -10px;
                list-style-type: none;
                display: grid;
                grid-template-columns: 1fr 300px;
                grid-gap: 30px;
                @media (max-width: 800px) {
                    grid-template-columns: 1fr;
                }
                .article-list-item {
                    h3 {
                        font-family: $playfair;
                        font-size: 1.5rem;
                        font-weight: 300;
                        margin: 5px 0 0;
                    }
                    &:first-child {
                        grid-column: 1;
                        grid-row: 1 / last-line;
                        grid-row-start: span 3;
                        position: relative;
                        z-index: 3;
                        img {
                            margin-bottom: 8px;
                        }
                        h3 {
                            font-family: $playfair;
                            font-size: 2.5rem;
                            font-weight: 300;
                            margin: 5px 0;
                            line-height: 2.5rem;
                            @media (max-width: 600px) {
                                font-size: 1.8rem;
                                line-height: 1;
                            }
                        }
                        p {
                            margin: 15px 0 0;
                            font-family: $opensans;
                        }
                    }
                }
                img {
                    width: 100%;
                    height: auto;
                }
                &.home-category-videos {
                    .article-list-image {
                        &.first-image {
                            position: relative;
                            a {
                                &:hover {
                                    h3 {
                                        text-decoration: underline;
                                    }
                                }
                                .play-container {
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                    background: rgb(255, 255, 255);
                                    background: linear-gradient(
                                        180deg,
                                        rgba(255, 255, 255, 0) 0%,
                                        rgba(0, 0, 0, 1) 75%
                                    );
                                    .play-content {
                                        padding: 2rem;
                                        @media (max-width: 800px) {
                                            padding: 1.5rem;
                                        }
                                        @media (max-width: 400px) {
                                            padding: 0.5rem;
                                        }
                                        h3 {
                                            color: $white;
                                            margin-bottom: 2rem;
                                            @media (max-width: 800px) {
                                                margin-bottom: 5vw;
                                                font-size: 8vw;
                                                line-height: 1;
                                            }
                                            a {
                                                color: $white;
                                            }
                                        }
                                        i {
                                            font-size: 4rem;
                                            color: $white;
                                            display: inline;
                                            vertical-align: middle;
                                            @media (max-width: 600px) {
                                                font-size: 10vw;
                                            }
                                        }
                                        .play-btn {
                                            margin: 0;
                                        }
                                        .play-txt {
                                            text-transform: uppercase;
                                            color: #fff;
                                            font-family: 'Open Sans';
                                            font-weight: 700;
                                            letter-spacing: 1px;
                                            display: inline;
                                            margin-left: 10px;
                                            font-size: 1.1rem;
                                            @media (max-width: 800px) {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .play-container .play-btn {
                            margin: -30px 0 20px 10px;
                            color: #fff;
                            text-shadow: 0 0 10px #999;
                            @media (max-width: 800px) {
                                margin-top: -75px;
                            }
                            @media (max-width: 600px) {
                                margin: -14vw 0 7vw 4vw;
                            }

                            .fa-play-circle {
                                @media (max-width: 800px) {
                                    font-size: 4rem;
                                }
                                @media (max-width: 600px) {
                                    font-size: 10vw;
                                }
                            }
                        }
                    }
                }
            }
        }
        .home-special-report-container {
            margin-top: 80px;
        }
        &.lower-content {
            display: grid;
            grid-gap: 30px;
            grid-template-columns: 1fr 300px;
            margin-top: 4rem;
            @media (max-width: 1300px) {
                grid-template-columns: 1fr;
            }
            .current-issue {
                background: $black;
                padding: 50px;
                margin-bottom: 3rem;
                @media (max-width: 600px) {
                    margin-left: -20px;
                    margin-right: -20px;
                    padding: 50px 0;
                }
                .issue-container {
                    &:before {
                        border-top: 3px solid $white;
                        border-left: 3px solid $white;
                        height: 150px;
                        width: 325px;
                        content: '';
                        display: inline-block;
                        @media (max-width: 1000px) {
                            border-top: none;
                            border-left: none;
                        }
                    }
                    .contained-issue {
                        position: relative;
                        margin: 0 auto;
                        margin-top: -180px;
                        .issue-positioning {
                            display: grid;
                            grid-gap: 50px;
                            grid-template-columns: 232px 1fr;
                            margin-top: 3rem;
                            align-items: center;
                            margin-left: 50px;
                            @media (max-width: 700px) {
                                margin: 1.8rem auto 0;
                                text-align: center;
                                grid-template-columns: 1fr;
                            }
                            .issue-left {
                                position: relative;
                                &:after {
                                    border-bottom: 3px solid $white;
                                    height: 100px;
                                    width: 200px;
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    right: -3px;
                                    bottom: 1px;
                                    border-right: 3px solid $white;
                                    @media (max-width: 700px) {
                                        display: none;
                                    }
                                }
                            }
                            .issue-right {
                                @media (max-width: 600px) {
                                    padding: 0 40px;
                                }
                                .story-list {
                                    margin: 0;
                                    padding: 0;
                                    list-style-type: none;
                                    li {
                                        font-size: 1.6rem;
                                        padding: 10px 0;
                                        @media (max-width: 600px) {
                                            font-size: 1.3rem;
                                            text-align: left;
                                        }
                                        &:first-of-type {
                                            padding-top: 0;
                                        }
                                        &:last-of-type {
                                            padding-top: 0;
                                            &:after {
                                                border-bottom: 0;
                                            }
                                        }
                                        &:after {
                                            border-bottom: 1px solid #808080;
                                            content: '';
                                            display: block;
                                            width: 200px;
                                            height: 1px;
                                            padding-top: 20px;
                                            @media (max-width: 700px) {
                                                width: 100%;
                                                margin: auto;
                                            }
                                        }
                                    }
                                }
                                .subscribe-btn {
                                    @media (max-width: 600px) {
                                        width: 100%;
                                    }
                                }
                                .gift-btn {
                                    @media (max-width: 600px) {
                                        width: 100%;
                                        margin-top: 20px;
                                    }
                                }
                                .renew-btn {
                                    @media (max-width: 600px) {
                                        width: 100%;
                                        margin-top: 20px;
                                    }
                                }
                            }
                        }
                    }
                    h2 {
                        color: $white;
                        text-align: center;
                        margin: 0;
                        padding: 0;
                        font-size: 2.5rem;
                        line-height: 2rem;
                        font-weight: 400;
                        @media (max-width: 600px) {
                            font-size: 1.8rem;
                        }
                        span {
                            font-family: $opensans;
                            font-size: 1.25rem;
                            letter-spacing: 2px;
                            text-transform: uppercase;
                            font-weight: 700;
                            @media (max-width: 600px) {
                                font-size: 0.8rem;
                            }
                        }
                    }
                    ol,
                    ul {
                        a {
                            color: $white;
                        }
                    }
                }
            }
            .most-popular-large {
                margin-bottom: 0;
                .article-list-item {
                    padding-bottom: 0;
                    .article-list-text:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    p.section a:hover {
        text-decoration: none;
    }
}

// .home-newsletter {
//     background: $black;
//     padding: 25px 0 20px;
//     margin: 3rem 0;
//     color: $white;
//     a {
//         color: $white;
//         text-decoration: underline;
//         &:hover {
//             text-decoration: none;
//         }
//     }
//     .leade {
//         display: block;
//         text-align: center;
//         p {
//             display: block;
//             font-family: $opensans;
//             font-size: 1.65rem;
//             margin-top: 0;
//             margin-bottom: 10px;
//             line-height: normal;
//             @media (max-width: 600px) {
//                 margin-bottom: 20px;
//                 font-size: 1.5rem;
//                 text-align: left;
//                 display: block;
//             }
//         }
//         form {
//             display: inline;
//             @media (max-width: 600px) {
//                 text-align: left;
//                 display: block;
//             }
//             input[type='email'] {
//                 border: 1px solid #000;
//                 color: #808080;
//                 background: $white;
//                 padding: 14px;
//                 vertical-align: top;
//                 margin-top: 3px;
//                 text-transform: none;
//                 font-family: $opensans;
//                 letter-spacing: 1px;
//                 width: 330px;
//                 margin-left: 10px;
//                 @media (max-width: 600px) {
//                     width: 51vw;
//                     margin: 3px 2vw 3px 0;
//                     display: inline-block;
//                 }
//             }
//             input[type='submit'] {
//                 width: 140px;
//                 margin-top: 3px;
//                 font-size: 1.2rem;
//                 padding: 9px 10px 10px;
//                 margin-left: 20px;
//                 background: $pink;
//                 border: none;
//                 color: $white;
//                 font-family: $opensans;
//                 font-weight: 700;
//                 text-transform: uppercase;
//                 position: relative;
//                 top: 1px;
//                 cursor: pointer;
//                 @media (max-width: 600px) {
//                     width: 25vw;
//                 }
//                 &:hover {
//                     background: #808080;
//                     color: #fff;
//                 }
//             }
//             input.smithsonian {
//                 background: $smithsonian;
//                 color: $black;
//             }
//             input.travel {
//                 background: $travel;
//             }
//             input.history {
//                 background: $history;
//             }
//             input.science {
//                 background: $science;
//                 color: $black;
//             }
//         }
//     }
// }

.hide-toolbar {
    padding: 0;

    #cms-top {
        display: none;
    }

    header.main {
        margin-top: -46px;
    }

    header.main .navigation {
        top: 0 !important;
    }
}

.photo-of-the-day-main {
    img {
        max-width: 100%;
        margin-top: 30px;
    }
    .caption {
        font-size: 2rem;
        font-family: $playfair;
    }
}

.full-width-with-graphic {
    .desktopImage {
        display: block;
        @media (max-width: 400px) {
            display: none;
        }
    }
    .mobileImage {
        display: none;
        @media (max-width: 400px) {
            display: block;
        }
    }
}
.house-graphic-with-text {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 20px 0;
    .desktopImage {
        display: block;
        @media (max-width: 400px) {
            display: none;
        }
    }
    .mobileImage {
        display: none;
        @media (max-width: 400px) {
            display: block;
        }
    }
    h3 {
        font-family: $playfair;
        font-weight: normal;
        a {
            font-family: $playfair;
            font-weight: normal;
            font-size: 1.5rem;
            margin-top: 10px;
        }
    }
}
