.longform {
    .in-article-newsletter {
        max-width: 650px;
        margin: 2rem auto;
    }
}

.email-src-add {
    background: #fff3cd;
    padding: 0 10px;
    font-weight: 700;
    p {
        margin: 0;
        padding: 0;
    }
}

.in-article-newsletter {
    padding: 10px 0 20px;
    margin: 2rem 0;
    @media (max-width: 600px) {
        margin-top: 10px;
    }
    &.home-newsletter {
        padding: 25px 50px;
        margin: 3rem 0;
        border-top: 3px solid $black;
        background: #f5f5f5;
        @media (max-width: 600px) {
            padding: 10px 15px;
        }
        .leade {
            h3 {
                font-family: $opensans;
            }
            form {
                .twocol {
                    input {
                        width: 90%;
                        @media (max-width: 400px) {
                            width: 100%;
                        }
                    }
                }
                input[type='email'] {
                    width: 83%;
                    @media (max-width: 400px) {
                        width: 100%;
                    }
                }
            }
        }
    }
    a {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    .leade {
        display: block;
        h3 {
            font-family: $opensans;
            font-size: 1.35rem;
            margin-bottom: 5px;
        }
        p {
            display: block;
            font-family: $opensans;
            font-size: 1.45rem;
            margin-top: 5px;
            padding-top: 0;
            margin-bottom: 10px;
            line-height: normal;
            color: $black;
            text-align: left;
            @media (max-width: 600px) {
                margin-bottom: 20px;
                font-size: 1.3rem;
                margin-bottom: 5px;
                display: block;
            }
        }
        form {
            display: inline;
            @media (max-width: 600px) {
                text-align: left;
                display: block;
            }
            .twocol {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 10px;
                input {
                    border: 2px solid $black;
                    color: #808080;
                    background: $white;
                    padding: 16px 24px;
                    letter-spacing: 1px;
                    width: 85%;

                    @media (max-width: 1200px) {
                        width: 85%;
                    }

                    @media (max-width: 800px) {
                        width: 85%;
                    }

                    @media (max-width: 600px) {
                        width: 100%;
                        margin: 0 auto;
                        display: block;
                        box-sizing: border-box;
                        margin-bottom: 5px;
                    }
                }
            }

            input[type='email'] {
                border: 2px solid $black;
                color: #808080;
                background: $white;
                padding: 16px 24px;
                letter-spacing: 1px;
                width: 73%;

                @media (max-width: 1200px) {
                    width: 62%;
                }

                @media (max-width: 800px) {
                    width: 63%;
                }

                @media (max-width: 600px) {
                    width: 100%;
                    margin: 0 auto;
                    display: block;
                    box-sizing: border-box;
                }
            }

            input[type='submit'] {
                margin-top: 3px;
                width: 144px;
                background: $black;
                padding: 16px 0 17px 0;
                border: 0;
                color: $white;
                position: relative;
                top: 2px;
                margin-left: -5px;
                cursor: pointer;
                font-family: $opensans;
                font-size: 18px;
                font-weight: 700;
                line-height: 1;

                @media (max-width: 600px) {
                    width: 100%;
                    max-width: none;
                    margin: 4px auto 0;
                    display: block;
                    box-sizing: border-box;
                }
            }
        }
    }
}

.terms-info {
    font-family: $opensans !important;
    font-size: 0.75rem !important;
    margin-bottom: 0;
    margin: 5px auto 0;
    display: block !important;
    @media (max-width: 600px) {
        text-align: left;
        padding-bottom: 0;
        margin-bottom: 0;
    }
    a {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}

.preferenceGrid {
    &.email {
        margin-bottom: 10px !important;
    }
    &.partner-sites {
        display: grid;
        grid-template-columns: 20px 1fr;
        gap: 10px;
        margin-bottom: 0 !important;
        i {
            margin-top: 7px;
            margin-left: 7px;
        }
        p {
            margin-left: 0 !important;
        }
    }
}

.field-group-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 20px;
    gap: 10px;
    .field {
        label {
            display: inline;
            font-size: 1.3rem;
            font-weight: 700;
        }
    }
    input {
        width: 100%;
    }
}

.partner_journeys_opt_subscribed,
.partner_store_opt_subscribed {
    label {
        em {
            font-weight: normal;
            font-size: 1rem;
        }
    }
}

.buttonbox {
    text-align: center;
    font-size: 0.9rem;
    .required-field {
        font-size: 0.8rem !important;
        .required {
            color: #c00;
        }
    }
}
