.advertisement.fullwidth.adtop {
    padding: 10px 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
}

.advertisement.fullwidth.adtop.nosticky {
    margin-top: 0 !important;
    padding: 10px 0 !important;
    position: relative !important;
    top: inherit !important;
    left: inherit !important;
    right: inherit !important;
    z-index: inherit !important;
}

/* Sticky leaderboard ad on homepage and article pages */
.advertisement.fullwidth.adtop.sticky {
    margin-top: 20px;
    padding: 10px 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
}

/* Sticky sidebar ads on article pages */
#smithsonianmag_rail_right_1.sticky,
.ad-sidebar-wrapper .ad-slot.sticky {
    position: fixed;
    top: 100px;
    z-index: 999;
}

/* global advertisement elements */
.advertisement {
    &.fullwidth {
        background: #f8f8f8;
        text-align: center;
        margin: 0 auto;
        padding: 50px 0;
    }
}

.ad-slot-no-title:before {
    display: none;
}

// ADS
// width > 1048
.ad-wrapper {
    height: 380px;
}

.ad-sidebar-wrapper {
    display: none;

    @media (min-width: 1000px) {
        position: relative;
        display: block;
        width: 300px;
        z-index: 4;

        .ad-slot {
            position: sticky;
            top: 80px;
            z-index: 5;
            width: 300px;
            min-height: 250px;
            margin-bottom: 24px;
        }
    }
}

.longform {
    .ad-sidebar-wrapper {
        width: 160px;
        .ad-slot {
            width: 160px;
        }
    }
}

.ad-slot-video {
}

.ad-slot {
    display: grid;
    margin: 0 auto;
    place-content: center;
    &.ad-slot-header {
        display: block !important;
    }
}

.ad-wrapper.calc-full-width {
    height: 380px;
    // width: 100vw;
    // left: 50%;
    // transform: translateX(-50%);
}
.ad-container {
    display: flex;
    text-align: center;
    width: 100%;
    max-width: 1076px;
    position: absolute;

    .ad-slot {
        width: 100%;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        margin: 24px auto;
        height: 380px;
        display: grid;
        place-items: center;
    }
}

.article-columns.single-column .ad-container {
    // center ad for single column articles
    right: -800px;
}

.ad-article-slot {
    max-height: 360px;
    align-self: center;
}

#leaderboardAd {
    padding: 0;
    background: $black;
}

#smithsonianmag_leaderboard_atf {
    margin: 0 auto;
    background: #000000;
}
