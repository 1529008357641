.container {
    max-width: $desktop_large;
    margin: 0 auto;
    padding: 0 20px;
    .video {
        margin-top: 4rem;
        position: relative;
        h1 {
            font-family: $playfair;
            font-size: 2.75rem;
            font-weight: 300;
            line-height: 1.5;
            @media (max-width: 600px) {
                padding: 5px 0 10px;
                text-align: center;
                line-height: 1;
            }
        }
        .main-content-video {
            .video-header {
                h1 {
                    font-family: $playfair;
                    font-size: 2.813rem;
                    font-weight: 400;
                    line-height: 2.813rem;
                    margin: 0;
                    padding: 0;

                    @media (max-width: 600px) {
                        font-size: 2rem;
                        line-height: 1;
                    }
                }
                p.subtitle {
                    font-family: $opensans;
                    font-size: 1.4rem;
                    line-height: 1.3;
                    font-weight: 400;
                    margin: 21px 0 17px 0;
                    padding: 0;

                    @media (max-width: 600px) {
                        font-size: 1.1rem;
                    }
                }
            }
        }
        p .textlabel {
            display: none;
        }
        .sectionheading {
            margin-bottom: 40px;
            h1 {
                margin: 0;
                font-family: $opensans;
                font-size: 1.2rem;
                text-transform: uppercase;
                letter-spacing: 0.1rem;
            }
        }
        .tag-list {
            margin-top: 40px;
            nav {
                margin-left: -4px;
                a {
                    display: inline-block;
                    font-family: $playfair;
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: 0.85rem;
                    background: #f9f9fa;
                    padding: 4px 20px;
                    margin: 4px;
                }
            }
        }
        .category-label {
            border-top: 13px solid #000;
            h3 {
                margin: 0;
                padding: 1px 25px 3px 15px;
                background-color: #000;
                color: #fff;
                font-weight: bold;
                font-family: 'Open Sans', sans-serif;
                font-size: 1.2rem;
                text-transform: uppercase;
                line-height: 1.1;
                letter-spacing: 2px;
                position: relative;
                top: -14px;
                display: inline-block;
                z-index: 1;
                &:after {
                    content: '';
                    width: 30px;
                    height: 13px;
                    background-color: #c3d22d;
                    display: block;
                    position: absolute;
                    top: 12px;
                    right: -20px;
                    transform: skewX(-45deg);
                }
                &.ingenuity:after {
                    background-color: #f8e208;
                }
                &.travel:after {
                    background-color: #f26328;
                }
                &.arts-culture:after {
                    background-color: #a24b9d;
                }
                &.history:after {
                    background-color: #059ad1;
                }
            }
        }
        .view-all {
            font-family: $opensans;
            font-size: 0.9rem;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 1px;
            position: absolute;
            top: 15px;
            right: 0;
        }
        .videoContainer {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 40px;
            max-width: $desktop_large;
            @media (max-width: 1000px) {
                grid-template-columns: repeat(3, 1fr);
            }
            @media (max-width: 800px) {
                grid-template-columns: repeat(2, 1fr);
            }
            @media (max-width: 600px) {
                grid-template-columns: repeat(1, 1fr);
            }
            .videoThumb {
                a:hover {
                    text-decoration: none;
                }
                .videoImage {
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                .videoTitle {
                    p {
                        font-family: $opensans;
                        font-size: 1.125rem;
                        font-weight: 600;
                        line-height: 20px;
                        padding-top: 12px;
                        margin-top: 0;

                        .video-duration {
                            font-weight: 400;
                        }
                    }
                }
                .video-category {
                    margin-top: 6px;
                    font-family: $opensans;
                    font-size: 0.8rem;
                    font-weight: 700;
                    text-transform: uppercase;
                    text-decoration: underline;
                    text-decoration-thickness: 2px;
                    text-decoration-color: #afc736;
                    text-underline-offset: 2px;
                    letter-spacing: 1px;
                }
            }
        }
    }
}
