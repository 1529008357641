body.travel-south-main,
body.travel-south-louisiana,
body.travel-south-south-carolina,
body.travel-south-arkansas,
body.travel-south-kentucky,
body.travel-south-mississippi,
body.travel-south-missouri {
    background-size: 100%;
    .main-content {
        width: 1245px;
        padding: 0;
        @media (max-width: 1244px) {
            width: 100%;
        }
        .sponsored-custom-header {
            margin: 0 auto;
            font-family: $opensans;
            font-size: 1.5rem;
            max-width: 840px;
            width: 100%;
            text-align: center;
            margin-bottom: 50px;
            @media (max-width: 505px) {
                width: 90%;
            }
            img {
                padding: 150px 0 15px 0;
                max-width: 610px;
                width: 100%;
                @media (max-width: 505px) {
                    padding: 15px;
                }
            }
        }
        .sponsored-custom-elements {
            background-size: 194px 1507px;
            @media (max-width: 1100px) {
                background: transparent;
            }
            @media (max-width: 500px) {
                padding: 20px;
            }
            .sponsored_promo_item {
                cursor: pointer;
                img {
                    border: 2px solid $black;
                    filter: drop-shadow(5px 5px 5px #aaaaaa);
                }
                h2 {
                    font-family: $opensans;
                    font-size: 1.75rem;
                    text-transform: uppercase;
                    margin-bottom: 0;
                }
                p {
                    margin-top: 0;
                    font-family: $opensans;
                    font-size: 1.25rem;
                }
                &.left {
                    float: left;
                    width: 400px;
                    height: auto;
                    @media (max-width: 960px) {
                        float: none;
                        margin: 0 auto;
                    }
                    @media (max-width: 400px) {
                        width: 100%;
                    }
                    img {
                        width: 100%;
                        height: auto;
                    }
                    &.display_logo {
                        h2 {
                            text-transform: none;
                            font-size: 1.4375rem;
                            text-align: center;
                            color: $black;
                        }
                        img {
                            border: 0;
                            margin-top: 20px;
                            filter: drop-shadow(0 0 0 #fff);
                        }
                    }
                }
                &.right {
                    float: right;
                    width: 400px;
                    height: auto;
                    @media (max-width: 960px) {
                        float: none;
                        margin: 0 auto;
                    }
                    @media (max-width: 400px) {
                        width: 100%;
                    }
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                &:nth-of-type(1) {
                    margin-top: 100px;
                    @media (max-width: 1100px) {
                        margin-top: 0;
                    }
                }
                &:nth-of-type(2) {
                    margin-top: 340px;
                    @media (max-width: 1100px) {
                        margin-top: 0;
                    }
                }
                &:nth-of-type(3) {
                    margin-top: 110px;
                    @media (max-width: 1100px) {
                        margin-top: 0;
                    }
                }
                &:nth-of-type(4) {
                    margin-top: 120px;
                    @media (max-width: 1100px) {
                        margin-top: 0;
                    }
                }
                &:nth-of-type(5) {
                    margin-top: 120px;
                    @media (max-width: 1100px) {
                        margin-top: 0;
                    }
                }
                &:nth-of-type(6) {
                    margin-top: 200px;
                    @media (max-width: 1100px) {
                        margin-top: 0;
                    }
                    @media (max-width: 505px) {
                        margin-top: 70px;
                    }
                    h2 {
                        font-family: $opensans;
                        font-size: 1.4375rem;
                        color: $black;
                        text-transform: none;
                        margin-bottom: 15px;
                    }
                    img {
                        filter: none;
                        border: 0;
                    }
                }
            }
        }
    }
    .sponsored-custom-footer-wrapper {
        background: $white;
        .sponsored-custom-footer {
            clear: both;
            width: 1076px;
            margin: 0 auto;
            margin-top: 100px;
            padding: 50px 0;
            display: grid;
            column-gap: 50px;
            grid-template-columns: repeat(3, 1fr);
            align-items: center;
            justify-items: center;
            @media (max-width: 850px) {
                width: 90%;
                column-gap: 10px;
            }
            @media (max-width: 500px) {
                display: block;
                width: 90%;
            }
            img {
                width: 100%;
                height: auto;
            }
            strong {
                grid-column: span 3;
                text-align: left;
                align-self: auto;
                justify-self: baseline;
                font-size: 2rem;
                font-family: $opensans;
                margin-bottom: 20px;
                @media (max-width: 500px) {
                    grid-column: span 2;
                    text-align: center;
                }
            }
            p {
                grid-column: span 3;
                font-family: $opensans;
                @media (max-width: 500px) {
                    grid-column: span 2;
                }
            }
            .sponsored_logo_item {
                align-self: center;
                justify-self: center;
            }
        }
    }
    .sponsored-heading {
        text-align: left;
        padding: 10px 20px;
        .sponsored-text {
            display: inline;
            padding-top: 7px;
            vertical-align: middle;
            @media (max-width: 400px) {
                font-size: 0.8rem;
            }
        }
        .sponsored-logo {
            text-align: left;
            margin-top: 10px;
            display: inline-block;
            vertical-align: middle;
            margin-left: 20px;
            @media (max-width: 400px) {
                margin-left: -5px;
            }
            img {
                height: 36px;
                width: auto;
                position: relative;
                margin-top: -3px;
                @media (max-width: 400px) {
                    height: 32px;
                    margin-left: 5px;
                }
            }
        }
    }
    .modal {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: none;
        overflow: auto;
        background-color: $black;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 9999;
    }

    .modal-window {
        position: relative;
        background: $black;
        width: 50%;
        margin: 10% auto;
        padding: 20px;
        color: $white;
        font-family: $opensans;
        p {
            color: $white;
        }
        img {
            width: 350px;
            height: auto;
        }
        &.size {
            width: 800px;
            @media (max-width: 500px) {
                width: 300px;
            }
        }
        .modal-container {
            display: grid;
            grid-template-columns: 350px 1fr;
            gap: 30px;
            @media (max-width: 500px) {
                display: inline-block;
            }
            img {
                @media (max-width: 500px) {
                    width: 100%;
                    margin-top: 20px;
                }
            }
            .modal-right {
                h2 {
                    font-family: $opensans;
                    text-transform: uppercase;
                }
                p {
                    color: $white;
                    a {
                        text-decoration: underline;
                        color: $white;
                    }
                }
                a {
                    color: $white;
                    button {
                        text-transform: uppercase;
                        color: $white;
                        letter-spacing: 2px;
                        font-weight: 700;
                        border: 0;
                        padding: 8px 30px;
                        margin-top: 20px;
                    }
                }
            }
        }
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
        color: $white;
        height: 30px;
        width: 30px;
        font-size: 30px;
        line-height: 30px;
        text-align: center;
    }

    .close:hover,
    .close:focus {
        color: #000000;
        cursor: pointer;
    }

    .open {
        display: block;
    }
}

/* travel-south-main */
body.travel-south-main {
    .sponsored-custom-elements {
        p {
            padding-top: 50px;
            @media (max-width: 505px) {
                padding: 20px 20px 0;
            }
        }
    }
    .sponsored-custom-header {
        margin: 0 auto;
        font-family: $opensans;
        font-size: 1.5rem;
        max-width: 100% !important;
        width: 100%;
        text-align: center;
        margin-bottom: 50px;
        @media (max-width: 505px) {
            width: 100% !important;
        }
        &.outside-container {
            img {
                max-width: 100% !important;
            }
        }
        img {
            padding: 0 !important;
            max-width: 460px !important;
            width: 100%;
            @media (max-width: 505px) {
                padding: 15px;
            }
            &.downArrow {
                @media (max-width: 1699px) {
                    display: none;
                }
                @media (min-width: 1700px) {
                    width: auto;
                    height: auto;
                    padding: 0 !important;
                    display: inline-block;
                }
            }
        }
    }
    .main-content {
        p {
            margin-bottom: 0;
            font-family: $opensans;
            font-size: 1.25rem;
            text-align: center;
        }
    }
    .travel-south-desktop-map {
        @media (min-width: 601px) {
            display: block;
        }
        @media (max-width: 600px) {
            display: none;
        }
        .travelSouthMapWrapper {
            max-width: 988px;
            margin: auto 0;
            min-width: 320px;
            #map-set {
                position: relative;
                margin: 0 auto;
                padding-top: 3%;
                padding-left: 10%;
                width: 100%;
                #mapMO,
                #mapKY,
                #mapAR,
                #mapSC,
                #mapLA,
                #mapMS {
                    cursor: pointer;
                }
            }
        }
    }
    .travel-south-mobile-map {
        @media (min-width: 601px) {
            display: none;
        }
        @media (max-width: 600px) {
            display: block;
        }
    }
}

/* travel-south-louisiana */
body.travel-south-louisiana {
    .sponsored-custom-elements {
        background-image: url('https://tf-cmsv2-staging-smithsonianmag-media.s3.amazonaws.com/filer_public/86/4c/864c4325-5aa7-40e6-8112-553f56e3115e/dottedlines-wpoints.svg');
        background-repeat: no-repeat;
        background-position: top center;
        .sponsored_promo_item {
            h2 {
                color: #3d4370;
            }
        }
    }
    .modal-right {
        a {
            button {
                background: #3d4370;
            }
        }
    }
}

/* travel-south-south-carolina */
body.travel-south-south-carolina {
    .sponsored-custom-elements {
        background-image: url('https://tf-cmsv2-smithsonianmag-media.s3.amazonaws.com/filer_public/0a/8d/0a8dd555-f970-463f-b147-a5563bd4136b/southcarolina-dottedtravelline.svg');
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 194px 1507px;
        .sponsored_promo_item {
            h2 {
                color: #1e427d;
            }
        }
    }
    .modal-right {
        a {
            button {
                background: #2f8bbf;
            }
        }
    }
}

/* travel-south-arkansas */
body.travel-south-arkansas {
    .sponsored-custom-elements {
        background-image: url('https://tf-cmsv2-smithsonianmag-media.s3.amazonaws.com/filer_public/86/ec/86ec8505-3a78-4f0c-9181-d0a1e1c7c30e/arkansas-dottedtravelline.svg');
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 194px 1507px;
        .sponsored_promo_item {
            h2 {
                color: #07324a;
            }
        }
    }
    .modal-right {
        a {
            button {
                background: #3b8fc0;
            }
        }
    }
}

/* travel-south-kentucky */
body.travel-south-kentucky {
    .sponsored-custom-elements {
        background-image: url('https://tf-cmsv2-smithsonianmag-media.s3.amazonaws.com/filer_public/db/b2/dbb2d46f-057e-4469-8c94-3b875865fdb3/kentucky-dottedtravelline.svg');
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 194px 1507px;
        .sponsored_promo_item {
            h2 {
                color: #003764;
            }
        }
    }
    .modal-right {
        a {
            button {
                background: #278dc8;
            }
        }
    }
}

/* travel-south-mississippi */
body.travel-south-mississippi {
    .sponsored-custom-elements {
        background-image: url('https://tf-cmsv2-smithsonianmag-media.s3.amazonaws.com/filer_public/d9/6c/d96cd103-a5f7-4283-ba36-cf379287cdfc/mississippi-dottedtravelline.svg');
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 194px 1507px;
        .sponsored_promo_item {
            h2 {
                color: #012227;
            }
        }
    }
    .modal-right {
        a {
            button {
                background: #8daa89;
            }
        }
    }
}

/* travel-south-missouri */
body.travel-south-missouri {
    .sponsored-custom-elements {
        background-image: url('https://tf-cmsv2-smithsonianmag-media.s3.amazonaws.com/filer_public/a7/58/a7583185-219b-438c-abf3-49af46c12372/missouri-dottedtravelline.svg');
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 194px 1507px;
        .sponsored_promo_item {
            h2 {
                color: #3f213a;
            }
        }
    }
    .modal-right {
        a {
            button {
                background: #8e4489;
            }
        }
    }
}

/* travel-south-fall-2023 */
.fall-23-travel-south,
.fall-23-travel-south-missouri,
.fall-23-travel-south-mississippi,
.fall-23-travel-south-north-carolina,
.fall-23-travel-south-south-carolina,
.spring-24-travel-south-alabama,
.spring-24-travel-south-louisiana,
.spring-24-travel-south-kentucky,
.spring-24-travel-south-mississippi {
    background-size: 100%;
    background-color: #191818;
    footer {
        background: $white;
    }
    .dotted-down-main-page {
        text-align: center;
        margin-top: 70px;
        @media (max-width: 500px) {
            margin-top: 60px;
        }
    }
    .sponsored-custom-header {
        margin: 100px auto 70px;
        font-family: $opensans;
        font-size: 1.2rem;
        max-width: 740px;
        width: 100%;
        text-align: center;
        color: $white;
        @media (max-width: 850px) {
            width: 100%;
        }
        @media (max-width: 505px) {
            width: 100% !important;
        }
        &.outside-container {
            img {
                max-width: 100% !important;
            }
        }
        .dotted-down {
            margin-top: 50px;
            img {
                width: 12px;
                height: auto;
            }
        }
        img {
            padding: 0 !important;
            max-width: 460px !important;
            width: 100%;
            @media (max-width: 500px) {
                padding: 15px;
            }
        }
    }
    .sponsored-custom-elements {
        max-width: 1060px;
        margin: 0 auto;
        .sponsored_promo_list_container {
            margin: 0;
            padding: 0;
            margin-bottom: 75px;
            img {
                width: 100%;
            }
            .sponsored_promo_list_item {
                position: relative;
                color: $white;
                .mobile {
                    display: none;
                    @media (max-width: 500px) {
                        display: inline-block;
                    }
                }
                .desktop {
                    display: inline-block;
                    @media (max-width: 500px) {
                        display: none;
                    }
                }
                .copy-background {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: rgb(0, 0, 0);
                    background: linear-gradient(
                        180deg,
                        rgba(0, 0, 0, 0) 0%,
                        rgba(0, 0, 0, 1) 100%
                    );
                    width: 100%;
                    box-sizing: border-box;
                    padding: 30px 0 10px 0;
                    h2 {
                        font-family: $opensans;
                        font-size: 1.75rem;
                        text-transform: uppercase;
                        margin-bottom: 0;
                        color: $white;
                        padding: 0 20px;
                        @media (max-width: 1000px) {
                            font-size: 3vw;
                        }
                        @media (max-width: 600px) {
                            font-size: 5vw;
                        }
                    }
                    p {
                        margin-top: 0;
                        font-family: $opensans;
                        font-size: 0.9rem;
                        color: #ddd;
                        padding: 0 20px;
                        font-weight: bold;

                        @media (max-width: 1000px) {
                            font-size: 1.6vw;
                        }

                        @media (max-width: 600px) {
                            font-size: 3vw;
                        }
                    }
                    .with-map {
                        &:before {
                            font-family: 'Font Awesome 6 Pro';
                            content: '\f3c5';
                            margin-right: 10px;
                        }
                    }
                }
            }
            .sponsored_promo_text_item {
                color: $white;
                font-size: 0.95rem;
                font-family: $opensans;
                line-height: 1.4rem;
                a {
                    color: $white;
                    text-decoration: underline;
                    &:hover {
                        text-decoration: none;
                    }
                    button {
                        text-transform: uppercase;
                        color: $white;
                        letter-spacing: 2px;
                        font-weight: 700;
                        border: 0;
                        padding: 15px 30px;
                        margin-top: 10px;
                    }
                }
            }
        }
        figure {
            position: relative;
        }
        @media (max-width: 1150px) {
            width: auto;
        }
        .sponsored_promo_item {
            cursor: pointer;
            margin: 0;
            @media (max-width: 600px) {
                margin-bottom: 30px;
            }
            img {
                position: relative;
            }
            .copy-background {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                background: rgb(0, 0, 0);
                background: linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 1) 100%
                );
                width: 100%;
                box-sizing: border-box;
                padding: 30px 0 10px 0;
                h2 {
                    font-family: $opensans;
                    font-size: 1.75rem;
                    text-transform: uppercase;
                    margin-bottom: 0;
                    color: $white;
                    padding: 0 20px;
                    @media (max-width: 1000px) {
                        font-size: 3vw;
                    }
                    @media (max-width: 600px) {
                        font-size: 5vw;
                    }
                }
                p {
                    margin-top: 0;
                    font-family: $opensans;
                    font-size: 0.9rem;
                    color: #ddd;
                    padding: 0 20px;
                    font-weight: bold;

                    @media (max-width: 1000px) {
                        font-size: 1.6vw;
                    }

                    @media (max-width: 600px) {
                        font-size: 3vw;
                    }

                    &:before {
                        font-family: 'Font Awesome 6 Pro';
                        content: '\f3c5';
                        margin-right: 10px;
                    }
                }
            }
            &.left {
                float: left;
                width: 500px;
                height: auto;
                @media (max-width: 1150px) {
                    width: 44.5vw;
                }
                @media (max-width: 600px) {
                    float: none;
                    width: 100%;
                }
                img {
                    width: 100%;
                    height: auto;
                }
                &.display_logo {
                    h2 {
                        text-transform: none;
                        font-size: 1.4375rem;
                        text-align: center;
                        color: $black;
                    }
                    img {
                        border: 0;
                        margin-top: 20px;
                        filter: drop-shadow(0 0 0 #fff);
                    }
                    p {
                        img {
                            width: 165px;
                            margin: 0 auto;
                        }
                    }
                }
            }
            &.right {
                float: right;
                width: 500px;
                height: auto;
                @media (max-width: 1150px) {
                    width: 44.5vw;
                }
                @media (max-width: 600px) {
                    float: none;
                    width: 100%;
                }
                img {
                    width: 100%;
                    height: auto;
                }
                &.display_logo {
                    text-align: center;
                    h2 {
                        text-transform: none;
                        font-size: 1.4375rem;
                        text-align: center;
                        color: $white;
                    }
                    img {
                        border: 0;
                        margin-top: 20px;
                        width: 362px;
                        @media (max-width: 850px) {
                            width: 90%;
                        }
                        @media (max-width: 500px) {
                            width: 100%;
                        }
                    }
                    p {
                        margin-top: 30px;
                        img {
                            width: 165px;
                            margin: 0 auto;
                        }
                    }
                }
            }
            &.center {
                text-align: center;
                &.display_logo {
                    text-align: center;
                    h2 {
                        text-transform: none;
                        font-size: 1.4375rem;
                        text-align: center;
                        color: $white;
                        @media (max-width: 500px) {
                            font-size: 1.2rem;
                        }
                    }
                    img {
                        border: 0;
                        margin-top: 20px;
                        width: 362px;
                        @media (max-width: 850px) {
                            width: 362px;
                        }
                        @media (max-width: 500px) {
                            width: 80%;
                        }
                    }
                    p {
                        margin-top: 30px;
                        img {
                            width: 165px;
                            margin: 0 auto;
                        }
                    }
                }
            }
            &:nth-of-type(1) {
                margin-top: 0;
            }
            &:nth-of-type(2) {
                margin-top: 250px;
                @media (max-width: 850px) {
                    margin-top: 100px;
                }
                @media (max-width: 500px) {
                    margin-top: 0;
                }
            }
            &:nth-of-type(3) {
                margin-top: 50px;
                @media (max-width: 500px) {
                    margin-top: 0;
                }
            }
            &:nth-of-type(4) {
                margin-top: 50px;
                @media (max-width: 500px) {
                    margin-top: 0;
                }
            }
            &:nth-of-type(5) {
                margin-top: 50px;
                @media (max-width: 500px) {
                    margin-top: 0;
                }
            }
            &:nth-of-type(6) {
                margin-top: 100px;
                @media (max-width: 500px) {
                    margin-top: 70px;
                }
                h2 {
                    font-family: $opensans;
                    font-size: 1.4375rem;
                    color: $black;
                    text-transform: none;
                    margin-bottom: 15px;
                }
                img {
                    filter: none;
                    border: 0;
                }
            }
        }
    }
    .sponsored-custom-footer-wrapper {
        background: $white;
        .sponsored-custom-footer {
            clear: both;
            width: 1076px;
            margin: 0 auto;
            margin-top: 100px;
            padding: 50px 0;
            display: grid;
            column-gap: 50px;
            grid-template-columns: repeat(4, 1fr);
            align-items: center;
            justify-items: center;
            @media (max-width: 850px) {
                width: 90%;
                column-gap: 10px;
            }
            @media (max-width: 500px) {
                display: block;
                width: 90%;
            }
            img {
                width: 100%;
                height: auto;
            }
            h2 {
                grid-column: span 4;
                text-align: left;
                align-self: auto;
                justify-self: baseline;
                font-size: 2rem;
                font-family: $opensans;
                margin-bottom: 0;
                @media (max-width: 500px) {
                    grid-column: span 2;
                    text-align: center;
                }
            }
            p {
                grid-column: span 4;
                font-family: $opensans;
                @media (max-width: 500px) {
                    grid-column: span 2;
                }
            }
            .sponsored_logo_item {
                margin: 20px 10px;
                align-self: center;
                justify-self: center;
            }
        }
    }
    .sponsored-heading {
        text-align: left;
        padding: 10px 20px;
        @media (max-width: 850px) {
            padding: 10px 0 10px 20px;
        }
        .sponsored-text {
            display: inline;
            padding-top: 7px;
            vertical-align: middle;
            @media (max-width: 400px) {
                font-size: 0.8rem;
            }
        }
        .sponsored-logo {
            text-align: left;
            margin-top: 10px;
            display: inline-block;
            vertical-align: middle;
            margin-left: 20px;
            @media (max-width: 400px) {
                margin-left: -5px;
            }
            img {
                height: 36px;
                width: auto;
                position: relative;
                margin-top: -3px;
                @media (max-width: 400px) {
                    height: 32px;
                    margin-left: 5px;
                }
            }
        }
    }
    .sponsored-notice {
        margin-top: 0;
    }
    .modal {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: none;
        overflow: auto;
        background-color: $black;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 9999;
    }

    .modal-window {
        position: relative;
        background: $black;
        width: 50%;
        margin: 10% auto;
        padding: 50px;
        color: $white;
        font-family: $opensans;
        @media (max-width: 600px) {
            padding: 20px;
        }
        p {
            color: $white;
        }
        img {
            width: 350px;
            height: auto;
            @media (max-width: 500px) {
                width: 100%;
            }
        }
        &.size {
            width: 800px;
            @media (max-width: 1000px) {
                width: 80vw;
            }
            @media (max-width: 800px) {
                width: 75vw;
            }
        }
        .modal-container {
            font-size: 0.95rem;
            line-height: 1.3rem;
            img {
                width: 100%;
                margin-top: 20px;
            }
            h2 {
                font-family: $opensans;
                text-transform: uppercase;
                margin-bottom: 0;
            }
            p {
                color: $white;
                font-size: 0.95rem;
                &.sub-title {
                    font-size: 0.9rem;
                    font-weight: bold;
                    font-style: normal;
                    margin-top: 0;
                }
                a {
                    text-decoration: underline;
                    color: $white;
                }
            }
            a {
                color: $white;
                button {
                    text-transform: uppercase;
                    color: $white;
                    letter-spacing: 2px;
                    font-weight: 700;
                    border: 0;
                    padding: 8px 30px;
                    margin-top: 20px;
                    display: block;
                }
            }
        }
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
        color: $white;
        height: 30px;
        width: 30px;
        font-size: 30px;
        line-height: 30px;
        text-align: center;
    }

    .close:hover,
    .close:focus {
        color: #000000;
        cursor: pointer;
    }

    .open {
        display: block;
    }
}

.fall-23-travel-south-missouri {
    @media (max-width: 500px) {
        background-image: url('https://tf-cmsv2-smithsonianmag-media.s3.amazonaws.com/filer_public/a4/86/a486b254-b8e9-406c-ad30-6fe469d33d5b/missouri-mobile-background.jpg') !important;
    }
    .modal-window {
        .modal-container {
            a {
                button {
                    background: #8e4489;
                }
            }
        }
    }
    .sponsored_promo_text_item {
        a {
            button {
                background: #8e4489;
            }
        }
    }
    .sponsored-custom-elements .sponsored_promo_item .copy-background p:before {
        content: '';
        margin: 0;
    }
}

.fall-23-travel-south-mississippi {
    @media (max-width: 500px) {
        background-image: url('https://tf-cmsv2-smithsonianmag-media.s3.amazonaws.com/filer_public/3f/b4/3fb4dfa6-d79b-47f4-9097-500534fb74e7/mississippi-mobile-background.jpg') !important;
    }
    .modal-window {
        .modal-container {
            a {
                button {
                    background: #8daa89;
                }
            }
        }
    }
    .sponsored_promo_text_item {
        a {
            button {
                background: #8daa89;
            }
        }
    }
}

.fall-23-travel-south-north-carolina {
    @media (max-width: 500px) {
        background-image: url('https://tf-cmsv2-smithsonianmag-media.s3.amazonaws.com/filer_public/4f/2f/4f2f2882-c075-4c21-94f7-dbfb197f74de/nc-mobile-background.jpg') !important;
    }
    .modal-window {
        .modal-container {
            a {
                button {
                    background: #004e59;
                }
            }
        }
    }
    .sponsored_promo_text_item {
        a {
            button {
                background: #004e59;
            }
        }
    }
}

.fall-23-travel-south-south-carolina {
    @media (max-width: 500px) {
        background-image: url('https://tf-cmsv2-smithsonianmag-media.s3.amazonaws.com/filer_public/d1/36/d1368362-19b7-43c4-b69f-e0c8eac03bb6/sc-mobile-background.jpg') !important;
    }
    .modal-window {
        .modal-container {
            a {
                button {
                    background: #2f8bbf;
                }
            }
        }
    }
    .sponsored_promo_text_item {
        a {
            button {
                background: #2f8bbf;
            }
        }
    }
}

.spring-24-travel-south-alabama {
    @media (max-width: 500px) {
        background-image: url('https://tf-cmsv2-smithsonianmag-media.s3.amazonaws.com/filer_public/4f/21/4f21eb2f-ed49-4f9a-9c96-a56e647bf759/alabama-mobile-background.jpg') !important;
    }
    .modal-window {
        .modal-container {
            a {
                button {
                    background: #3e97b5;
                }
            }
        }
    }
    .sponsored_promo_text_item {
        a {
            button {
                background: #3e97b5;
            }
        }
    }
}

.spring-24-travel-south-louisiana {
    @media (max-width: 500px) {
        background-image: url('https://tf-cmsv2-smithsonianmag-media.s3.amazonaws.com/filer_public/d8/7e/d87e0c5d-cc81-4c08-a678-efe3960d3baa/louisiana-main-background-mobile.jpg') !important;
    }
    .modal-window {
        .modal-container {
            a {
                button {
                    background: #3d4370;
                }
            }
        }
    }
    .sponsored_promo_text_item {
        a {
            button {
                background: #3d4370;
            }
        }
    }
}
.spring-24-travel-south-kentucky {
    @media (max-width: 500px) {
        background-image: url('https://tf-cmsv2-smithsonianmag-media.s3.amazonaws.com/filer_public/ac/18/ac1814e7-c613-4166-a93e-8f534a7ab75f/kentucky-main-background-mobile.jpg') !important;
    }
    .modal-window {
        .modal-container {
            a {
                button {
                    background: #0082be;
                }
            }
        }
    }
    .sponsored_promo_text_item {
        a {
            button {
                background: #0082be;
            }
        }
    }
}
.spring-24-travel-south-mississippi {
    @media (max-width: 500px) {
        background-image: url('https://tf-cmsv2-smithsonianmag-media.s3.amazonaws.com/filer_public/d9/ba/d9bad113-7f4c-4e78-a735-17623dc3927d/mississippi-main-background-mobile.jpg') !important;
    }
    .modal-window {
        .modal-container {
            a {
                button {
                    background: #8daa89;
                }
            }
        }
    }
    .sponsored_promo_text_item {
        a {
            button {
                background: #8daa89;
            }
        }
    }
}

body.fall-23-travel-south {
    @media (max-width: 500px) {
        background-image: url('https://tf-cmsv2-smithsonianmag-media.s3.amazonaws.com/filer_public/0a/7e/0a7e9120-29f7-43dd-895b-e2694645166e/travelsouth-mobile-background.jpg') !important;
        background-repeat: no-repeat;
        background-position: top center;
    }
    .sponsored-custom-footer-wrapper {
        display: none;
    }
    .ts-main-logo-fall-2023 {
        text-align: center;
        margin: 0 auto;
        img {
            max-width: 930px;
            @media (max-width: 950px) {
                max-width: 90% !important;
                width: 90%;
                margin-bottom: 50px;
            }
        }
    }
    .sponsored-custom-elements {
        position: relative;
        top: -250px;
        @media (max-width: 600px) {
            top: -200px;
        }
        p {
            padding-top: 30px;
            color: $white;
            @media (max-width: 505px) {
                padding: 20px 20px 0;
            }
        }
        .ts-main-content {
            @media (max-width: 505px) {
                position: relative;
                margin-top: -100px;
            }
            p {
                @media (max-width: 505px) {
                    font-size: 1rem;
                    margin-top: 0;
                    padding-top: 0;
                }
            }
        }
    }
    .main-content {
        width: 100%;
        padding: 0;
        p {
            margin-bottom: 0;
            font-family: $opensans;
            font-size: 1.25rem;
            text-align: center;
        }
    }
    .sponsored-custom-header {
        margin: 0 auto;
        font-family: $opensans;
        font-size: 1.5rem;
        max-width: 100% !important;
        width: 100%;
        text-align: center;
        margin-bottom: 50px;
        @media (max-width: 505px) {
            width: 100% !important;
        }
        &.outside-container {
            img {
                max-width: 100% !important;
            }
        }
        .travel-south-desktop-main-header {
            height: 300px;
            width: 100%;
        }
        img {
            padding: 0 !important;
            max-width: 100% !important;
            width: 100%;
            @media (max-width: 505px) {
                padding: 15px;
            }
            &.downArrow {
                @media (max-width: 1699px) {
                    display: none;
                }
                @media (min-width: 1700px) {
                    width: auto;
                    height: auto;
                    padding: 0 !important;
                    display: inline-block;
                }
            }
        }
    }

    .travel-south-desktop-map {
        @media (min-width: 801px) {
            display: block;
            padding-bottom: 50px;
        }
        @media (max-width: 800px) {
            display: none;
        }
        .travelSouthMapWrapper {
            max-width: 988px;
            margin: auto 0;
            min-width: 320px;
            #map-set {
                position: relative;
                margin: 0 auto;
                padding-top: 3%;
                padding-left: 10%;
                width: 100%;
                #mapMO,
                #mapKY,
                #mapAR,
                #mapSC,
                #mapLA,
                #mapMS {
                    cursor: pointer;
                }
            }
        }
    }
    .travel-south-mobile-map {
        @media (min-width: 801px) {
            display: none;
        }
        @media (max-width: 800px) {
            display: block;
            padding-bottom: 40px;
        }
        a {
            color: $white;
        }
        img {
            max-width: 100%;
        }
    }
}

.sponsored_promo_item {
    &.center {
        &.display_logo {
            position: static;
        }
    }
}

.return-button {
    margin-top: 100px;
}

.ceros {
    .sponsored-notice {
        background: #f4f4f4;
        text-align: center;
        font-family: $opensans;
        padding: 10px 0;
        margin: 0;
        position: relative;
        margin-bottom: -50px;
    }
    .sponsored-heading-new {
        margin-top: 0 !important;
        .sponsored-text {
            font-family: $opensans;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 12px;
            text-align: center;
            margin-top: 0;
            padding: 20px 0;
        }
    }
}
